<template>
  <div class="carousel-table">
    <div
      class="carousel-slider"
      :style="{ transform: `translateX(-${currentPage * 100}%)` }"
    >
      <div
        v-for="(chunk, index) in chunkedData"
        :key="index"
        class="carousel-item"
      >
        <el-table
          stripe
          :data="chunk"
          :height="tableHeight"
          class="tableWrap"
          ref="rolltable"
        >
          <!-- 选择列 -->
          <el-table-column
            v-if="shelf"
            type="selection"
            width="55"
          ></el-table-column>
          <!-- 动态插槽处理 -->
          <template v-for="(item, colIndex) in tableTitle">
            <!-- 普通列 -->
            <el-table-column
              v-if="!item.childrens"
              :key="colIndex"
              :prop="item.value"
              :label="item.name"
              :width="item.width"
              align="center"
            >
              <template #default="scope">
                <!-- 使用默认插槽 -->
                <!-- 优先处理 check_images 特殊逻辑 -->
                <template v-if="item.value === 'check_images'">
                  <div class="image-container">
                    <!-- 处理单图片情况 -->
                    <template v-if="typeof scope.row[item.value] === 'string'">
                      <el-image
                        :src="scope.row[item.value]"
                        :preview-src-list="[scope.row[item.value]]"
                        style="width: 60px; height: 60px"
                      ></el-image>
                    </template>

                    <!-- 处理多图片数组 -->
                    <template v-else-if="Array.isArray(scope.row[item.value])">
                      <div class="image-list">
                        <el-image
                          v-for="(img, index) in scope.row[item.value]"
                          :key="index"
                          :src="img"
                          :preview-src-list="scope.row[item.value]"
                          style="width: 60px; height: 60px; margin-right: 8px"
                        ></el-image>
                      </div>
                    </template>

                    <!-- 处理空值/错误情况 -->
                    <template v-else>
                      <div class="image-slot">无图片</div>
                    </template>
                  </div>
                </template>
                <template v-else-if="item.value === 'business_images'">
                  <div class="image-container">
                    <!-- 处理单图片情况 -->
                    <template v-if="typeof scope.row[item.value] === 'string'">
                      <el-image
                        :src="scope.row[item.value]"
                        :preview-src-list="[scope.row[item.value]]"
                        style="width: 60px; height: 60px"
                      ></el-image>
                    </template>

                    <!-- 处理多图片数组 -->
                    <template v-else-if="Array.isArray(scope.row[item.value])">
                      <div class="image-list">
                        <el-image
                          v-for="(img, index) in scope.row[item.value]"
                          :key="index"
                          :src="img"
                          :preview-src-list="scope.row[item.value]"
                          style="width: 60px; height: 60px; margin-right: 8px"
                        ></el-image>
                      </div>
                    </template>

                    <!-- 处理空值/错误情况 -->
                    <template v-else>
                      <div class="image-slot">无图片</div>
                    </template>
                  </div>
                </template>
                <template v-else-if="item.value === 'pic_image'">
                  <div class="image-container">
                    <!-- 处理单图片情况 -->
                    <template v-if="typeof scope.row[item.value] === 'string'">
                      <el-image
                        :src="scope.row[item.value]"
                        :preview-src-list="[scope.row[item.value]]"
                        style="width: 60px; height: 60px"
                      ></el-image>
                    </template>

                    <!-- 处理多图片数组 -->
                    <template v-else-if="Array.isArray(scope.row[item.value])">
                      <div class="image-list">
                        <el-image
                          v-for="(img, index) in scope.row[item.value]"
                          :key="index"
                          :src="img"
                          :preview-src-list="scope.row[item.value]"
                          style="width: 60px; height: 60px; margin-right: 8px"
                        ></el-image>
                      </div>
                    </template>

                    <!-- 处理空值/错误情况 -->
                    <template v-else>
                      <div class="image-slot">无图片</div>
                    </template>
                  </div>
                </template>
                <template v-else-if="item.value === 'company_image'">
                  <div class="image-container">
                    <!-- 处理单图片情况 -->
                    <template v-if="typeof scope.row[item.value] === 'string'">
                      <el-image
                        :src="scope.row[item.value]"
                        :preview-src-list="[scope.row[item.value]]"
                        style="width: 60px; height: 60px"
                      ></el-image>
                    </template>

                    <!-- 处理多图片数组 -->
                    <template v-else-if="Array.isArray(scope.row[item.value])">
                      <div class="image-list">
                        <el-image
                          v-for="(img, index) in scope.row[item.value]"
                          :key="index"
                          :src="img"
                          :preview-src-list="scope.row[item.value]"
                          style="width: 60px; height: 60px; margin-right: 8px"
                        ></el-image>
                      </div>
                    </template>

                    <!-- 处理空值/错误情况 -->
                    <template v-else>
                      <div class="image-slot">无图片</div>
                    </template>
                  </div>
                </template>
                <template v-else-if="item.value === 'waste_image'">
                  <div class="image-container">
                    <!-- 处理单图片情况 -->
                    <template v-if="typeof scope.row[item.value] === 'string'">
                      <el-image
                        :src="scope.row[item.value]"
                        :preview-src-list="[scope.row[item.value]]"
                        style="width: 60px; height: 60px"
                      ></el-image>
                    </template>

                    <!-- 处理多图片数组 -->
                    <template v-else-if="Array.isArray(scope.row[item.value])">
                      <div class="image-list">
                        <el-image
                          v-for="(img, index) in scope.row[item.value]"
                          :key="index"
                          :src="img"
                          :preview-src-list="scope.row[item.value]"
                          style="width: 60px; height: 60px; margin-right: 8px"
                        ></el-image>
                      </div>
                    </template>

                    <!-- 处理空值/错误情况 -->
                    <template v-else>
                      <div class="image-slot">无图片</div>
                    </template>
                  </div>
                </template>
                <template v-else-if="item.value === 'arr_food_images'">
                  <div class="image-container">
                    <!-- 处理单图片情况 -->
                    <template v-if="typeof scope.row[item.value] === 'string'">
                      <el-image
                        :src="scope.row[item.value]"
                        :preview-src-list="[scope.row[item.value]]"
                        style="width: 60px; height: 60px"
                      ></el-image>
                    </template>

                    <!-- 处理多图片数组 -->
                    <template v-else-if="Array.isArray(scope.row[item.value])">
                      <div class="image-list">
                        <el-image
                          v-for="(img, index) in scope.row[item.value]"
                          :key="index"
                          :src="img"
                          :preview-src-list="scope.row[item.value]"
                          style="width: 60px; height: 60px; margin-right: 8px"
                        ></el-image>
                      </div>
                    </template>

                    <!-- 处理空值/错误情况 -->
                    <template v-else>
                      <div class="image-slot">无图片</div>
                    </template>
                  </div>
                </template>
                <template v-else-if="item.value === 'business_license_images_arr'">
                  <div class="image-container">
                    <!-- 处理单图片情况 -->
                    <template v-if="typeof scope.row[item.value] === 'string'">
                      <el-image
                        :src="scope.row[item.value]"
                        :preview-src-list="[scope.row[item.value]]"
                        style="width: 60px; height: 60px"
                      ></el-image>
                    </template>

                    <!-- 处理多图片数组 -->
                    <template v-else-if="Array.isArray(scope.row[item.value])">
                      <div class="image-list">
                        <el-image
                          v-for="(img, index) in scope.row[item.value]"
                          :key="index"
                          :src="img"
                          :preview-src-list="scope.row[item.value]"
                          style="width: 60px; height: 60px; margin-right: 8px"
                        ></el-image>
                      </div>
                    </template>

                    <!-- 处理空值/错误情况 -->
                    <template v-else>
                      <div class="image-slot">无图片</div>
                    </template>
                  </div>
                </template>
                <template v-else-if="item.value === 'purchase_voucher_images_arr'">
                  <div class="image-container">
                    <!-- 处理单图片情况 -->
                    <template v-if="typeof scope.row[item.value] === 'string'">
                      <el-image
                        :src="scope.row[item.value]"
                        :preview-src-list="[scope.row[item.value]]"
                        style="width: 60px; height: 60px"
                      ></el-image>
                    </template>

                    <!-- 处理多图片数组 -->
                    <template v-else-if="Array.isArray(scope.row[item.value])">
                      <div class="image-list">
                        <el-image
                          v-for="(img, index) in scope.row[item.value]"
                          :key="index"
                          :src="img"
                          :preview-src-list="scope.row[item.value]"
                          style="width: 60px; height: 60px; margin-right: 8px"
                        ></el-image>
                      </div>
                    </template>

                    <!-- 处理空值/错误情况 -->
                    <template v-else>
                      <div class="image-slot">无图片</div>
                    </template>
                  </div>
                </template>
                <template v-else-if="item.value === 'product_report_images_arr'">
                  <div class="image-container">
                    <!-- 处理单图片情况 -->
                    <template v-if="typeof scope.row[item.value] === 'string'">
                      <el-image
                        :src="scope.row[item.value]"
                        :preview-src-list="[scope.row[item.value]]"
                        style="width: 60px; height: 60px"
                      ></el-image>
                    </template>

                    <!-- 处理多图片数组 -->
                    <template v-else-if="Array.isArray(scope.row[item.value])">
                      <div class="image-list">
                        <el-image
                          v-for="(img, index) in scope.row[item.value]"
                          :key="index"
                          :src="img"
                          :preview-src-list="scope.row[item.value]"
                          style="width: 60px; height: 60px; margin-right: 8px"
                        ></el-image>
                      </div>
                    </template>

                    <!-- 处理空值/错误情况 -->
                    <template v-else>
                      <div class="image-slot">无图片</div>
                    </template>
                  </div>
                </template>
                <template v-else-if="item.value === 'imgs'">
                  <div class="image-container">
                    <!-- 处理单图片情况 -->
                    <template v-if="typeof scope.row[item.value] === 'string'">
                      <el-image
                        :src="scope.row[item.value]"
                        :preview-src-list="[scope.row[item.value]]"
                        style="width: 60px; height: 60px"
                      ></el-image>
                    </template>

                    <!-- 处理多图片数组 -->
                    <template v-else-if="Array.isArray(scope.row[item.value])">
                      <div class="image-list">
                        <el-image
                          v-for="(img, index) in scope.row[item.value]"
                          :key="index"
                          :src="img"
                          :preview-src-list="scope.row[item.value]"
                          style="width: 60px; height: 60px; margin-right: 8px"
                        ></el-image>
                      </div>
                    </template>

                    <!-- 处理空值/错误情况 -->
                    <template v-else>
                      <div class="image-slot">无图片</div>
                    </template>
                  </div>
                </template>
                <template v-else-if="item.value === 'other_report_images_arr'">
                  <div class="image-container">
                    <!-- 处理单图片情况 -->
                    <template v-if="typeof scope.row[item.value] === 'string'">
                      <el-image
                        :src="scope.row[item.value]"
                        :preview-src-list="[scope.row[item.value]]"
                        style="width: 60px; height: 60px"
                      ></el-image>
                    </template>

                    <!-- 处理多图片数组 -->
                    <template v-else-if="Array.isArray(scope.row[item.value])">
                      <div class="image-list">
                        <el-image
                          v-for="(img, index) in scope.row[item.value]"
                          :key="index"
                          :src="img"
                          :preview-src-list="scope.row[item.value]"
                          style="width: 60px; height: 60px; margin-right: 8px"
                        ></el-image>
                      </div>
                    </template>

                    <!-- 处理空值/错误情况 -->
                    <template v-else>
                      <div class="image-slot">无图片</div>
                    </template>
                  </div>
                </template>
                <template v-else-if="item.value === 'images_arr'">
                  <div class="image-container">
                    <!-- 处理单图片情况 -->
                    <template v-if="typeof scope.row[item.value] === 'string'">
                      <el-image
                        :src="scope.row[item.value]"
                        :preview-src-list="[scope.row[item.value]]"
                        style="width: 60px; height: 60px"
                      ></el-image>
                    </template>

                    <!-- 处理多图片数组 -->
                    <template v-else-if="Array.isArray(scope.row[item.value])">
                      <div class="image-list">
                        <el-image
                          v-for="(img, index) in scope.row[item.value]"
                          :key="index"
                          :src="img"
                          :preview-src-list="scope.row[item.value]"
                          style="width: 60px; height: 60px; margin-right: 8px"
                        ></el-image>
                      </div>
                    </template>

                    <!-- 处理空值/错误情况 -->
                    <template v-else>
                      <div class="image-slot">无图片</div>
                    </template>
                  </div>
                </template>
                <!-- 通用字段处理逻辑 -->
                <template v-else>
                  <!-- 优先使用具名插槽 -->
                  <slot
                    :name="item.value"
                    :scope="scope"
                    v-if="$scopedSlots[item.value]"
                  ></slot>

                  <!-- 默认内容显示 -->
                  <template v-else>
                    <!-- 原有逻辑保持不变 -->
                    <img
                      v-if="
                        item.value == 'due_days' && scope.row[item.value] < 30
                      "
                      src="../assets/image/isdue.png"
                      style="
                        width: 40px;
                        vertical-align: bottom;
                        margin-right: 8px;
                      "
                    />
                    <span
                      v-if="
                        item.value != 'due_days' || scope.row[item.value] >= 0
                      "
                      v-html="formatCellContent(scope.row, item.value)"
                    ></span>
                    <!-- 其他原有逻辑... -->
                  </template>
                </template>
              </template>
            </el-table-column>
            <!-- 嵌套列 -->
            <el-table-column v-else :label="item.label">
              <el-table-column
                v-for="(child, childIndex) in item.childrens"
                :key="childIndex"
                :prop="child.value"
                :label="child.name"
                :width="child.width"
                align="center"
              >
                <template slot-scope="scope">
                  <slot
                    :name="child.value"
                    :scope="scope"
                    v-if="$scopedSlots[child.value]"
                  ></slot>
                  <span v-else>{{ scope.row[child.value] }}</span>
                </template>
              </el-table-column>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <!-- 独立加载层 -->
      <div v-if="loading" class="global-loading-mask" style="min-height: 520px">
        <i class="el-icon-loading"></i>
        <p>拼命加载中</p>
      </div>

      <div
        v-if="!chunkedData?.length && !loading"
        style="text-align: center; width: 100%; margin-top: 180px"
      >
        <img src="../assets/image/NoImg.png" alt="" style="width: 285px" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselTable",
  props: {
    // 原有props保持不变
    tableDatas: Array,
    tableTitle: Array,
    // tabList: Array,
    tableHeight: [String, Number],
    shelf: Boolean,
    rect: Boolean,
    changeTalbe: Boolean,
    interval: {
      type: Number,
      default: 4000,
    },
  },
  data() {
    return {
      currentPage: 0,
      timer: null,
      loading: true,
    };
  },
  watch: {
    tableDatas: {
      handler(newVal) {
        if (Array.isArray(newVal) && newVal.length > 0) {
          this.loading = false;
        } else {
          this.loading = true;
        }
      },
      deep: true, // 保留深度监听，但增加空数组判断
      immediate: true,
    },
  },
  computed: {
    chunkedData() {
      const chunks = [];
      for (let i = 0; i < this.tableDatas?.length; i += 11) {
        chunks.push(this.tableDatas.slice(i, i + 11));
      }
      return chunks;
    },
  },
  methods: {
    // 添加内容格式化方法
    formatCellContent(row, field) {
      const value = row[field];
      if (!value) return "";

      if (typeof value === "string") {
        return value.replace(/\r\n/g, "<br>");
      }

      if (field === "due_days" && value >= 0) {
        return `${value}天`;
      }

      return value;
    },
    startAutoPlay() {
      this.timer = setInterval(() => {
        this.currentPage = (this.currentPage + 1) % this.chunkedData.length;
      }, 8000);
    },
    pause() {
      clearInterval(this.timer);
    },
    resume() {
      this.startAutoPlay();
    },
  },
  mounted() {
    this.startAutoPlay();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.carousel-table {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-slider {
  display: flex;
  transition: transform 0.1s ease-out 0.2s;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
  height: 530px;
  /* position: relative; */
  background-color: rgba(163, 160, 169, 0.4);
}
/* 全局加载层样式 */
.global-loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(64, 158, 255);
  z-index: 2000;
}

.el-icon-loading {
  font-size: 40px;
  margin-bottom: 16px;
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
