var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"border":"2px solid #00b58b","height":"100%","border-radius":"10px","padding":"0 10px"}},[(_vm.shop_name != '合肥十一中学食堂（优芙得）')?_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.shop_img}}),_c('div',{staticStyle:{"color":"#333"}},[_c('div',{staticClass:"shop_name"},[_c('span',[_vm._v(_vm._s(_vm.shop_name))])]),_c('div',{staticClass:"shop_owner"},[_c('span',[_vm._v("负 责 人 ：")]),_c('span',[_vm._v(_vm._s(_vm.shop_owner))])]),_c('div',{staticClass:"phone"},[_c('span',[_vm._v("联系电话：")]),_c('span',[_vm._v(_vm._s(_vm.shop_phone))])]),_c('div',{staticClass:"phone"},[_c('span',[_vm._v("餐厅面积：")]),_c('span',[_vm._v(_vm._s(_vm.area))])]),_c('div',{staticClass:"phone"},[_c('span',[_vm._v("就餐人数：")]),_c('span',[_vm._v(_vm._s(_vm.users_amount))])]),_c('div',{staticClass:"shop_address"},[_c('span',[_vm._v("餐厅地址：")]),_c('span',[_vm._v(_vm._s(_vm.shop_address))])])])]):_c('div',{staticStyle:{"color":"white","margin-left":"30px","font-weight":"bolder","font-size":"14px","position":"relative"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticStyle:{"width":"90%","height":"1px","background-color":"rgba(255, 255, 255, 0.5)","position":"absolute","top":"98px"}}),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin":"5px 0"}},[_c('span',[_vm._v("责任人姓名：")]),_c('span',[_vm._v("方建军")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-bottom":"5px"}},[_c('span',[_vm._v("职务：")]),_c('span',[_vm._v("安全处主任")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-bottom":"5px"}},[_c('span',[_vm._v("手机号码：")]),_c('span',[_vm._v("13856047387")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("电子邮箱：")]),_c('span',[_vm._v("634153012@qq.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"18px","margin-bottom":"5px"}},[_c('span',[_vm._v("食堂责任人姓名：")]),_c('span',[_vm._v("仇保萍")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-bottom":"5px"}},[_c('span',[_vm._v("职务：")]),_c('span',[_vm._v("食堂经理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-bottom":"5px"}},[_c('span',[_vm._v("手机号码：")]),_c('span',[_vm._v("15155127728")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("电子邮箱：")]),_c('span',[_vm._v("3031939648@qq.com")])])
}]

export { render, staticRenderFns }