<template>
  <div>
    <div style="display: flex">
      <div
        class="tabs"
        @click="handleClick(item, index)"
        v-for="(item, index) in tabList"
        :style="{
          borderColor: index === cIndex ? '#a27b67' : '#ccc',
          color: index === cIndex ? '#a27b67' : '#ccc',
        }"
      >
        {{ item.label }}
      </div>
    </div>
    <!--  && caipuList?.length > 0 -->
    <el-carousel
      style="width: 1060px; height: 545px; text-align: center; padding: 10px 0"
      class="right-ul"
      indicator-position="none"
      :arrow="contorl"
      :interval="5000"
      v-if="currentTable == '每周菜谱'"
    >
      <el-carousel-item v-for="(item, index) in caipuList" :key="index">
        <div class="right-li">
          <el-image
            class="img"
            style="
              width: 95%;
              height: 525px;
              border-radius: 15px;
              opacity: 0.95;
            "
            :src="item.big_img"
            :preview-src-list="[item.big_img]"
          ></el-image>
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-carousel
      style="width: 1060px; height: 545px; text-align: center; padding: 10px 0"
      class="right-ul"
      indicator-position="none"
      :arrow="contorl"
      :interval="5000"
      v-if="currentTable == '供应商资质管理'"
    >
      <el-carousel-item
        v-for="(group, index) in groupedSuppList"
        :key="index"
        style="display: flex; justify-content: space-evenly"
      >
        <div
          v-for="(item, subIndex) in group"
          :key="subIndex"
          class="right-li"
          style="width: 33%; position: relative; top: -20px"
        >
          <el-image
            class="img"
            style="
              transform: scale(0.7);
              object-fit: contain;
              object-position: center;
              padding: 4px;
              border: 1px solid #f6f6f6;
            "
            :src="item.business_images"
            :preview-src-list="[item.business_images]"
          >
          </el-image>
          <div
            style="
              padding: 10px 30px;
              position: absolute;
              bottom: 30px;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
            "
          >
            {{ item.name }}
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-carousel
      style="width: 1060px; height: 545px; text-align: center; padding: 10px 0"
      class="right-ul"
      :arrow="contorl"
      :interval="5000"
      indicator-position="none"
      v-if="currentTable == '菜品营养分析'"
    >
      <el-carousel-item v-for="(item, index) in analysisList" :key="index">
        <div
          class="right-li"
          style="
            background-color: white;
            padding: 10px 0;
            width: 95%;
            margin: 0 auto;
          "
        >
          <el-image
            class="img"
            style="width: 95%; height: 505px; opacity: 0.95"
            :src="item"
            :preview-src-list="analysisList"
          ></el-image>
        </div>
      </el-carousel-item>
    </el-carousel>

    <el-carousel
      style="width: 1060px; height: 545px; text-align: center; padding: 10px 0"
      class="right-ul"
      :arrow="contorl"
      :interval="5000"
      indicator-position="none"
      v-if="currentTable == customTitle"
    >
      <el-carousel-item v-for="(item, index) in customImgs" :key="index">
        <div class="right-li">
          <el-image
            class="img"
            style="
              width: 95%;
              height: 525px;
              border-radius: 15px;
              opacity: 0.95;
            "
            :src="item"
            :preview-src-list="customImgs"
          ></el-image>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div
      class="carousel-tables"
      v-if="
        currentTable !== '每周菜谱' &&
        currentTable != customTitle &&
        currentTable !== '供应商资质管理' &&
        currentTable !== '菜品营养分析'
      "
    >
      <div
        class="carousel-slider"
        :style="{ transform: `translateX(-${currentPage * 100}%)` }"
      >
        <div
          v-for="(chunk, index) in chunkedData"
          :key="index"
          class="carousel-item"
        >
          <el-table
            stripe
            :data="chunk"
            :height="510"
            class="tableWrap"
            ref="rolltable"
          >
            <!-- 选择列 -->
            <el-table-column
              v-if="shelf"
              type="selection"
              width="55"
            ></el-table-column>
            <!-- 动态插槽处理 -->
            <template v-for="(item, colIndex) in tableTitle">
              <!-- 普通列 -->
              <el-table-column
                v-if="!item.childrens"
                :key="colIndex"
                :prop="item.value"
                :label="item.name"
                :width="item.width"
                align="center"
              >
                <template #default="scope">
                  <!-- 使用默认插槽 -->
                  <!-- 优先处理 check_images 特殊逻辑 -->
                  <template v-if="item.value === 'check_images'">
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 15px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 60px; height: 15px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template v-else-if="item.value === 'business_images'">
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 60px; height: 15px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template v-else-if="item.value === 'pic_image'">
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 60px; height: 15px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template v-else-if="item.value === 'company_image'">
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 60px; height: 15px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template v-else-if="item.value === 'waste_image'">
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 60px; height: 15px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template v-else-if="item.value === 'arr_food_images'">
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 60px; height: 15px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template
                    v-else-if="item.value === 'business_license_images_arr'"
                  >
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 40px; height: 40px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template
                    v-else-if="item.value === 'purchase_voucher_images_arr'"
                  >
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 60px; height: 15px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template v-else-if="item.value === 'scene_img'">
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 60px; height: 15px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template v-else-if="item.value === 'picture_img'">
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list" style="display: flex">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 40px; height: 40px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template
                    v-else-if="item.value === 'product_report_images_arr'"
                  >
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 60px; height: 15px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template v-else-if="item.value === 'imgs'">
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 60px; height: 15px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template
                    v-else-if="item.value === 'other_report_images_arr'"
                  >
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 60px; height: 15px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template v-else-if="item.value === 'license_images_arr'">
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 40px; height: 40px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <template v-else-if="item.value === 'images_arr'">
                    <div class="image-container">
                      <!-- 处理单图片情况 -->
                      <template
                        v-if="typeof scope.row[item.value] === 'string'"
                      >
                        <el-image
                          :src="scope.row[item.value]"
                          :preview-src-list="[scope.row[item.value]]"
                          style="width: 60px; height: 60px"
                        ></el-image>
                      </template>

                      <!-- 处理多图片数组 -->
                      <template
                        v-else-if="Array.isArray(scope.row[item.value])"
                      >
                        <div class="image-list">
                          <el-image
                            v-for="(img, index) in scope.row[item.value]"
                            :key="index"
                            :src="img"
                            :preview-src-list="scope.row[item.value]"
                            style="width: 60px; height: 15px; margin-right: 8px"
                          ></el-image>
                        </div>
                      </template>

                      <!-- 处理空值/错误情况 -->
                      <template v-else>
                        <div class="image-slot">无图片</div>
                      </template>
                    </div>
                  </template>
                  <!-- 通用字段处理逻辑 -->
                  <template v-else>
                    <!-- 优先使用具名插槽 -->
                    <slot
                      :name="item.value"
                      :scope="scope"
                      v-if="$scopedSlots[item.value]"
                    ></slot>

                    <!-- 默认内容显示 -->
                    <template v-else>
                      <!-- 原有逻辑保持不变 -->
                      <img
                        v-if="
                          item.value == 'due_days' && scope.row[item.value] < 30
                        "
                        src="../assets/image/isdue.png"
                        style="
                          width: 40px;
                          vertical-align: bottom;
                          margin-right: 8px;
                        "
                      />
                      <span
                        v-if="
                          item.value != 'due_days' && item.value != 'health' || scope.row[item.value] >= 0
                        "
                        v-html="formatCellContent(scope.row, item.value)"
                      ></span>
                      <span
                        v-if="
                          item.value == 'health'"
                        :style="item.value === 'health' && scope.row[item.value].includes('不合格') ? 'color: red;' : ''"
                        v-html="formatHealthContent(scope.row, item.value)"
                      ></span>
                      <!-- 其他原有逻辑... -->
                    </template>
                  </template>
                </template>
              </el-table-column>
              <!-- 嵌套列 -->
              <el-table-column v-else :label="item.label">
                <el-table-column
                  v-for="(child, childIndex) in item.childrens"
                  :key="childIndex"
                  :prop="child.value"
                  :label="child.name"
                  :width="child.width"
                  align="center"
                >
                  <template slot-scope="scope">
                    <slot
                      :name="child.value"
                      :scope="scope"
                      v-if="$scopedSlots[child.value]"
                    ></slot>
                    <span v-else>{{ scope.row[child.value] }}</span>
                  </template>
                </el-table-column>
              </el-table-column>
            </template>
          </el-table>
        </div>
        <!-- 独立加载层 -->
        <!-- <div
          v-if="loading"
          class="global-loading-mask"
          style="min-height: 520px"
        >
          <i class="el-icon-loading"></i>
          <p>拼命加载中</p>
        </div> -->

        <!-- <div
          v-if="!chunkedData?.length"
          style="text-align: center; width: 100%; margin-top: 180px"
        >
          <img src="../assets/image/NoImg.png" alt="" style="width: 285px" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import CarouselTable from "./tableComBanner.vue";
import {
  getTastingTable,
  getMenuTable,
  getRetentionTable,
  getWasteTable,
  getCzjxdTable,
  getHealthcheckTable,
  getCjxdTable,
  getArea8cleanTable,
  getPsgcleanTable,
  getCzjselfcheckTable,
  getColdstorecheckTable,
  getPestcontrolTable,
  getCloseshopTable,
  getSupplierTable,
  getPriceView,
  getDDUserTemp,
  getPesticideheck,
  getERPTruckTzList,
  getXdjl,
  getProblemList,
  getPesticideBymachine,
  getFoodTjj,
  getFogetFoodbzqodTjj,
  getCustomImgs,
  getTruckTzList,
  getBlocks,
  getFoodAnalysis,
  getMorningCheck,
  getMorningCheckByMachine,
} from "../api/api";
import { baseImgUrl } from "../utils/request";
// 引入混入
// import { myMixin } from "../common/common.js";
export default {
  data() {
    return {
      intervalId2: null,
      activeName: "1",
      customImgs: [],
      customTitle: "",
      currentTable: "",
      currentIndex: 0,
      contorl: "hover",
      cIndex: 0,
      //
      currentImg: "",
      show_big_img: false,
      caipuList: [],
      alltabsDate: {},
      tableTitle: [],
      tableSource: [],
      requestData: {
        start_date: "",
        end_date: "",
        page: 1,
        search_key: "",
        cat_name: "",
        from: "inner",
      },
      suppList: [],
      customTitle: "",
      showScore: 0,
      showFoodimages: 0,
      flag: false,
      tabList: [],
      // 新加
      currentPage: 0,
      timer: null,
      loading: true,
      show_price: false,
      analysisList: [],
    };
  },
  // mixins: [myMixin],
  props: ["shelf", "tabList2"],
  components: {
    CarouselTable,
  },
  methods: {
    // 获取大屏展示内容
    async getBlocksTable() {
      try {
        const res = await getBlocks({ from: "home" });
        this.tabList =
          this.tabList2 ||
          res.data.data.map((str) => ({
            label: str === "自定义图片模块" ? this.customTitle : str,
          }));

        // 定义异步方法映射
        const methodMap = {
          每日留样: () => this.getRetentionTables(),
          价格公示: () => this.getPriceAndInitializeTable(),
          每周菜谱: () => this.getMenuTables(),
          供应商资质管理: () => this.getSupplierTables(),
          消毒记录: () => this.getXdjlTable(),
          员工每日体温记录: () => this.getDDUserTemp(),
          智能农残检测仪: () => this.getPesticideBymachineData(),
          食品保质期: () => this.getFogetFoodbzqodTjjTable(),
          食品添加剂: () => this.getfoodsTable(),
          农残检测: () => this.getPesticideheckTable(),
          产品入库溯源记录: () => this.getTruckTzListTable(),
          整改记录: () => this.getProblemListData(),
          每日入库台账: () => this.getTruckTzLists(),
          菜品营养分析: () => this.getgetFoodAnalysisData(),
          每日晨检: () => this.getMorningCheck(),
          智能晨检仪: () => this.getMorningCheckByMachine(),
        };

        // 收集所有异步任务
        const promises = this.tabList
          .filter((item) => methodMap[item.label])
          .map((item) => methodMap[item.label]());

        await Promise.all(promises);
        setTimeout(() => {
          this.handleClick(this.tabList[0], 0);
        }, 100);
      } catch (error) {
        console.error("初始化失败:", error);
        this.loading = false;
      }
    },
    async handleClick(tab, i) {
      this.cIndex = i;
      this.currentIndex = i;
      if (tab?.label == "每周菜谱") {
        if (this.show_big_img) {
          this.currentTable = "每周菜谱";
          this.contorl = "always";
        } else {
          this.tableSource = this.allTabsDate["每周菜谱"];
          this.tableTitle = [
            {
              name: "日期",
              width: "",
              value: "date",
            },
            {
              name: "餐次",
              width: "",
              value: "meal_name",
            },
            {
              name: "类别",
              width: "",
              value: "menu_name",
            },
            {
              name: "套餐内容",
              width: "600",
              value: "food_content",
            },
          ];
        }
      } else if (tab.label == "菜品营养分析") {
        this.currentTable = "菜品营养分析";
        this.contorl = "always";
      } else if (tab?.label == "品尝记录") {
        this.currentTable = "";
        this.getTastingTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "餐次",
            width: "",
            value: "meal_name",
          },
          {
            name: "档口",
            width: "",
            value: "room_name",
          },
          {
            name: "菜品名称",
            width: "",
            value: "food_name",
          },
          {
            name: "中心温度(摄氏度)",
            width: "",
            value: "temp",
          },
          {
            name: "品尝数量",
            width: "",
            value: "taste_number",
          },
          {
            name: "品尝结果",
            width: "",
            value: "taste_result",
          },
          {
            name: "品尝人",
            width: "",
            value: "taste_user",
          },
        ];
      } else if (tab?.label == "每日留样") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["每日留样"];
        if (this.tableSource === undefined) {
          this.getRetentionTables(2);
        }
        this.tableTitle = [
          {
            name: "留样时间",
            width: "",
            value: "action_date",
          },
          {
            name: "餐次",
            width: "",
            value: "meal_name",
          },
          {
            name: "菜品名称",
            width: "",
            value: "food_name",
          },
          {
            name: "保存条件(摄氏度)",
            width: "",
            value: "temp",
          },
          {
            name: "重量",
            width: "",
            value: "weight",
          },
          {
            name: "留样人",
            width: "",
            value: "retention_user",
          },
          {
            name: "复审人",
            width: "",
            value: "verify_name",
          },
        ];
      } else if (tab?.label == "废弃物处理") {
        this.currentTable = "";
        this.getWasteTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "action_date",
          },
          {
            name: "种类",
            width: "",
            value: "class",
          },
          {
            name: "数量",
            width: "",
            value: "number",
          },
          {
            name: "联系方式",
            width: "",
            value: "phone",
          },
          {
            name: "经手人",
            width: "",
            value: "handler_user_name",
          },
          {
            name: "回收人",
            width: "",
            value: "user_name",
          },
          {
            name: "图片",
            width: "",
            value: "waste_image",
          },
          {
            name: "资质图片",
            width: "",
            value: "company_image",
          },
          {
            name: "回收单位",
            width: "",
            value: "company",
          },
        ];
      } else if (tab?.label == "晨检记录") {
        this.currentTable = "";
        this.getHealthcheckTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "姓名",
            width: "",
            value: "user_name",
          },
          {
            name: "性别",
            width: "",
            value: "sex",
          },
          {
            name: "健康状况",
            width: "",
            value: "health_type",
          },
          {
            name: "卫生状况",
            width: "",
            value: "hygiene_type",
          },
          {
            name: "体温",
            width: "",
            value: "temp",
          },
        ];
      } else if (tab?.label == "餐具消毒") {
        this.currentTable = "";
        this.getCjxdTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "档口",
            width: "",
            value: "room_name",
          },
          {
            name: "餐别名称",
            width: "",
            value: "meal_name",
          },
          {
            name: "消毒餐具",
            width: "",
            value: "disinfection_name",
          },
          {
            name: "数量",
            width: "",
            value: "number",
          },
          {
            name: "消毒开始时间",
            width: "",
            value: "start_time",
          },
          {
            name: "消毒结束时间",
            width: "",
            value: "end_time",
          },
          {
            name: "操作人",
            width: "",
            value: "operator_user",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab?.label == "操作间消毒") {
        this.getCzjxdTable();
        this.currentTable = "";
        this.tableTitle = [
          {
            name: "处理时间",
            width: "",
            value: "date",
          },
          {
            name: "操作间名称",
            width: "",
            value: "room_name",
          },
          {
            name: "消毒名称",
            width: "",
            value: "disinfection_name",
          },
          {
            name: "消毒方案",
            width: "",
            value: "method",
          },
          {
            name: "消毒开始时间",
            width: "",
            value: "start_time",
          },
          {
            name: "消毒结束时间",
            width: "",
            value: "end_time",
          },
          {
            name: "负责人",
            width: "",
            value: "operator_user",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "消毒图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab?.label == "区域八净") {
        this.currentTable = "";
        this.getArea8cleanTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "清洁区域",
            width: "",
            value: "clean_area",
          },
          {
            name: "操作人",
            width: "",
            value: "operator_user",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab?.label == "排水沟清洁") {
        this.currentTable = "";
        this.getPsgcleanTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "操作间",
            width: "",
            value: "clean_area",
          },
          {
            name: "清洁方式",
            width: "",
            value: "clean_area",
          },
          {
            name: "操作人",
            width: "",
            value: "operator_user",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab?.label == "操作间自检") {
        this.currentTable = "";
        this.getCzjselfcheckTable();

        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "自检区",
            width: "",
            value: "room_name",
          },
          {
            name: "自检内容",
            width: "",
            value: "check_item",
          },
          {
            name: "自检情况",
            width: "",
            value: "check_result",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab?.label == "冷库巡检") {
        this.currentTable = "";
        this.getColdstorecheckTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "档口",
            width: "",
            value: "room_name",
          },
          {
            name: "冰库冰柜编码",
            width: "",
            value: "code",
          },
          {
            name: "巡察温度(℃)",
            width: "",
            value: "tmp",
          },
          {
            name: "巡察时间",
            width: "",
            value: "check_time",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "审核人",
            width: "",
            value: "verify_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab?.label == "虫害防治") {
        this.currentTable = "";
        this.getPestcontrolTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "档口",
            width: "",
            value: "room_name",
          },
          {
            name: "操作间",
            width: "",
            value: "opertor_name",
          },
          {
            name: "防治类型",
            width: "",
            value: "method",
          },
          {
            name: "提交时间",
            width: "",
            value: "check_time",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "审核人",
            width: "",
            value: "verify_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (tab?.label == "闭店安检") {
        this.currentTable = "";
        this.getCloseshopTable();
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "门窗紧闭",
            width: "",
            value: "doorandwindow",
          },
          {
            name: "水闸、燃气、电闸关闭",
            width: "",
            value: "water_ele_gas",
          },
          {
            name: "是否休息",
            width: "",
            value: "is_close",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
        ];
      } else if (tab?.label == "供应商资质管理") {
        this.currentTable = "供应商资质管理";
        this.tableSource = this.allTabsDate["供应商资质管理"];
        if (this.showScore) {
          if (this.showFoodimages) {
            this.tableTitle = [
              {
                name: "供应商名称",
                width: "380",
                value: "name",
              },
              {
                name: "营业执照",
                width: "",
                value: "business_images",
              },
              {
                name: "许可证",
                width: "",
                value: "arr_food_images",
              },
              {
                name: "评分",
                width: "",
                value: "score",
              },
            ];
          } else {
            this.tableTitle = [
              {
                name: "供应商名称",
                width: "280",
                value: "name",
              },
              {
                name: "营业执照",
                width: "",
                value: "business_images",
              },
              {
                name: "评分",
                width: "",
                value: "score",
              },
            ];
          }
        } else {
          if (this.showFoodimages) {
            this.tableTitle = [
              {
                name: "供应商名称",
                width: "280",
                value: "name",
              },
              {
                name: "营业执照",
                width: "",
                value: "business_images",
              },
              {
                name: "许可证",
                width: "",
                value: "arr_food_images",
              },
            ];
          } else {
            this.tableTitle = [
              {
                name: "供应商名称",
                width: "380",
                value: "name",
              },
              {
                name: "营业执照",
                width: "",
                value: "business_images",
              },
            ];
          }
        }
      } else if (tab?.label == "价格公示") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["价格公式"];
        this.tableTitle = [
          { name: "档口", width: "", value: "gearOpening" },
          { name: "产品名", width: "", value: "name" },
          { name: "价格", width: "", value: "price" },
        ];
      } else if (tab?.label == "员工每日体温记录") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["体温检测"];
        this.tableTitle = [
          { name: "姓名", width: "", value: "name" },
          { name: "工号", width: "", value: "jobnum" },
          { name: "体温", width: "", value: "temp" },
          { name: "状态", width: "", value: "temp_status_name" },
          { name: "检测时间", width: "", value: "created_time" },
        ];
      } else if (tab?.label == "农残检测") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["农残检测"];
        this.tableTitle = [
          { name: "检测日期", width: "", value: "date" },
          { name: "菜品", width: "", value: "food_name" },
          { name: "供应商", width: "250", value: "supplier" },
          { name: "农残检测图片", width: "", value: "check_images" },
          { name: "检测结果", width: "", value: "check" },
          { name: "结果判定", width: "", value: "status_name" },
          { name: "检测人", width: "", value: "check_user" },
        ];
      } else if (tab?.label == "产品入库溯源记录") {
        this.currentTable = "产品入库溯源记录";
        this.tableSource = this.allTabsDate["产品入库溯源记录"];
        if (this.show_price) {
          this.tableTitle = [
            {
              name: "日期",
              width: "110",
              value: "add_date",
            },
            {
              name: "产品名称",
              width: "180",
              value: "goods_name",
            },
            // {
            //   name: "数量",
            //   width: "",
            //   value: "number",
            // },
            {
              name: "价格",
              width: "",
              value: "price",
            },
            {
              name: "生产批号",
              width: "110",
              value: "batch_number",
            },
            {
              name: "供货商",
              width: "200",
              value: "supplier",
            },
            {
              name: "营业执照",
              width: "90",
              value: "business_license_images_arr",
            },
            {
              name: "许可证",
              width: "",
              value: "license_images_arr",
            },
            {
              name: "外观检查",
              width: "90",
              value: "surface_check",
            },
            // {
            //   name: "产品检验报告",
            //   width: "",
            //   value: "product_report_images_arr",
            // },
            // {
            //   name: "供货凭证",
            //   width: "",
            //   value: "purchase_voucher_images_arr",
            // },
            {
              name: "记录人",
              width: "",
              value: "record_user",
            },
          ];
        } else {
          this.tableTitle = [
            {
              name: "日期",
              width: "",
              value: "add_date",
            },
            {
              name: "产品名称",
              width: "",
              value: "goods_name",
            },
            {
              name: "数量",
              width: "",
              value: "number",
            },
            {
              name: "生产批号",
              width: "",
              value: "batch_number",
            },
            {
              name: "供货商",
              width: "180",
              value: "supplier",
            },
            {
              name: "营业执照",
              width: "",
              value: "business_license_images_arr",
            },
            {
              name: "许可证",
              width: "",
              value: "license_images_arr",
            },
            // {
            //   name: "产品检验报告",
            //   width: "",
            //   value: "product_report_images_arr",
            // },
            // {
            //   name: "供货凭证",
            //   width: "",
            //   value: "purchase_voucher_images_arr",
            // },
            {
              name: "记录人",
              width: "",
              value: "record_user",
            },
          ];
        }
      } else if (tab?.label == "消毒记录") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["消毒记录"];
        this.tableTitle = [
          {
            name: "消毒日期",
            width: "120",
            value: "date",
          },
          {
            name: "部门",
            width: "250",
            value: "room_name",
          },
          {
            name: "餐具名称",
            width: "",
            value: "name",
          },
          // {
          //   name: "餐具图片",
          //   width: "",
          //   value: "pic_images",
          // },
          {
            name: "数量",
            width: "",
            value: "number",
          },
          {
            name: "消毒方式",
            width: "",
            value: "method",
          },
          {
            name: "开始时间",
            width: "",
            value: "start_time",
          },
          {
            name: "结束时间",
            width: "",
            value: "end_time",
          },
          {
            name: "操作人",
            width: "",
            value: "operator_user",
          },
        ];
      } else if (tab?.label == "食品添加剂") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["食品添加剂"];
        this.tableTitle = [
          {
            name: "添加剂名称",
            width: "",
            value: "product_name",
          },
          {
            name: "生产时间",
            width: "",
            value: "date_batch",
          },
          {
            name: "添加时间",
            width: "",
            value: "use_date",
          },
          {
            name: "使用量",
            width: "",
            value: "use_number",
          },
          {
            name: "剩余量",
            width: "",
            value: "left_number",
          },
          {
            name: "使用人",
            width: "",
            value: "use_name",
          },
        ];
      } else if (tab?.label == "整改记录") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["整改记录"];
        this.tableTitle = [
          {
            name: "整改编号",
            width: "",
            value: "id",
          },
          {
            name: "标题",
            width: "180",
            value: "title",
          },
          {
            name: "类型",
            width: "180",
            value: "type",
          },
          {
            name: "时间",
            width: "150",
            value: "created_time",
          },
          {
            name: "受理人",
            width: "",
            value: "operation_user",
          },
          {
            name: "问题图片",
            width: "",
            value: "images_arr",
          },
          {
            name: "状态",
            width: "",
            value: "status",
          },
        ];
      } else if (tab?.label == "智能农残检测仪") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["智能农残检测仪"];
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "菜品",
            width: "",
            value: "food_name",
          },
          {
            name: "供应商",
            width: "250",
            value: "supplier",
          },
          // {
          //   name: "检测值",
          //   width: "",
          //   value: "jiancezhi",
          // },
          // {
          //   name: "检测图片",
          //   width: "280",
          //   value: "imgs",
          // },
          {
            name: "检测状态",
            width: "",
            value: "status_name",
          },
          {
            name: "检测人",
            width: "",
            value: "check_user",
          },
        ];
      } else if (tab?.label == "食品保质期") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["食品保质期"];
        this.tableTitle = [
          {
            name: "产品名称",
            width: "",
            value: "food_name",
          },
          {
            name: "制作日期",
            width: "",
            value: "make_date",
          },
          {
            name: "到期时间",
            width: "",
            value: "due_date",
          },
          {
            name: "仓库名称",
            width: "",
            value: "room_name",
          },
          {
            name: "产品图片",
            width: "",
            value: "pic_image",
          },
          {
            name: "责任人",
            width: "",
            value: "owner",
          },
        ];
      } else if (tab.label == "每日入库台账") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["每日入库台账"];
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "add_date",
          },
          {
            name: "产品名称",
            width: "",
            value: "goods_name",
          },
          {
            name: "规格",
            width: "",
            value: "specs",
          },
          {
            name: "数量",
            width: "",
            value: "number",
          },
          {
            name: "生产批号",
            width: "",
            value: "batch_number",
          },
          {
            name: "外观检查",
            width: "",
            value: "surface_check",
          },
          {
            name: "记录人",
            width: "",
            value: "record_user",
          },
        ];
      } else if (tab.label == "每日晨检") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["每日晨检"];
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "姓名",
            width: "",
            value: "name",
          },
          {
            name: "晨检状态",
            width: "",
            value: "status",
          },
          {
            name: "体温",
            width: "",
            value: "temp",
          },
        ];
      } else if (tab.label == "智能晨检仪") {
        this.currentTable = "";
        this.tableSource = this.allTabsDate["智能晨检仪"];
        this.tableTitle = [
          {
            name: " 晨检日期",
            width: "",
            value: "date",
          },
          {
            name: "员工姓名",
            width: "",
            value: "personName",
          },
          {
            name: "人脸识别照片",
            width: "",
            value: "scene_img",
          },
          {
            name: "手部照片",
            width: "",
            value: "picture_img",
          },
          // {
          //   name: "检查结果",
          //   width: "",
          //   value: "picture_recognition_content",
          // },
          {
            name: "体温",
            width: "",
            value: "temperature",
          },
          {
            name: "晨检结果",
            width: "",
            value: "health",
          },
          {
            name: "检查时间",
            width: "",
            value: "checktime_text",
          },
        ];
      } else if (tab?.label == this.customTitle) {
        this.contorl = "always";
        this.currentTable = this.customTitle;
      }
    },
    // 获取品尝记录数据
    async getTastingTable() {
      const res = await getTastingTable();
      this.tableSource = res.data.data[0].data;
    },
    // 每周菜谱
    async getMenuTables() {
      const res = await getMenuTable();
      this.show_big_img = res.data.data[0]?.show_big_img;
      if (this.show_big_img) {
        this.caipuList = res.data.data[0].data;
      } else {
        this.allTabsDate["每周菜谱"] = res.data.data[0].data;
      }
    },
    // 自定义图片模块
    async getCustomImgs() {
      const res = await getCustomImgs();
      if (res.data.code == 1 && res.data.data != []) {
        this.customTitle = res.data.data.title;
        this.customImgs = res.data.data.imgs;
        this.getBlocksTable();
      } else {
        this.getBlocksTable();
      }
      if (!this.allTabsDate) {
        this.allTabsDate = {}; // 初始化
      }
      this.allTabsDate[this.customTitle] = this.customImgs;
    },
    // // 获取大屏展示内容
    // async getBlocksTable() {
    //   try {
    //     const res = await getBlocks({ from: "home" });
    //     // 设置 tabList
    //     this.tabList = res.data.data.map((str) => ({
    //       label: str === "自定义图片模块" ? this.customTitle : str,
    //     }));
    //   } catch (error) {
    //     console.error("初始化失败:", error);
    //     this.loading = false;
    //   }
    // },
    async getgetFoodAnalysisData() {
      const res = await getFoodAnalysis();
      const list = res.data.data?.map((item) => {
        if (!item.food_images?.includes("http") && item.food_images != "") {
          return {
            ...item,
            food_images:
              "https://shuwangysa.oss-cn-hangzhou.aliyuncs.com" +
              item.food_images,
            food_info: item.food_info?.map((foodItem) => ({
              ...foodItem,
              value: foodItem.percent,
            })),
          };
        } else {
          return {
            ...item,
            food_info: item.food_info?.map((foodItem) => ({
              ...foodItem,
              value: foodItem.percent,
            })),
          };
        }
      });

      this.analysisList = list?.map((item) => {
        return item.food_analysis_img;
      });
    },
    // 查看留样记录
    async getRetentionTables(index) {
      const res = await getRetentionTable();
      const retentionTable = res.data.data[0].data.map((item) => {
        if (!item.pic_image?.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
      // 安全操作：确保 allTabsDate 存在
      if (!this.allTabsDate) {
        this.allTabsDate = {}; // 初始化
      }
      this.allTabsDate["每日留样"] = retentionTable;
      if (index == 2) {
        this.handleClick(this.tabList[0], 0);
      }
    },
    // 废弃物处置记录
    async getWasteTable() {
      const res = await getWasteTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.waste_image.includes("http")) {
          return {
            ...item,
            waste_image: baseImgUrl + item.waste_image,
            company_image: baseImgUrl + item.company_image,
          };
        } else {
          return item;
        }
      });
    },
    // 获取晨检记录
    async getHealthcheckTable() {
      const res = await getHealthcheckTable();
      this.tableSource = res.data.data[0].data;
    },
    // 获取餐具消毒
    async getCjxdTable() {
      const res = await getCjxdTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
    },
    // 获取操作间消毒
    async getCzjxdTable() {
      const res = await getCzjxdTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
    },
    // 获取区域八净
    async getArea8cleanTable() {
      const res = await getArea8cleanTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
    },
    // 获取排水沟清洁
    async getPsgcleanTable() {
      const res = await getPsgcleanTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
    },
    // 获取操作间自检
    async getCzjselfcheckTable() {
      const res = await getCzjselfcheckTable();
      this.tableSource = res.data.data[0].data.map((item, index) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
            check_item: Object.keys(JSON.parse(item.check_item))[0], //你这个数据干嘛的你自己回头弄，反正就是这个有问题
          };
        } else {
          return item;
        }
      });
    },
    // 获取冷库巡检
    async getColdstorecheckTable() {
      const res = await getColdstorecheckTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
    },
    // 获取虫害防治
    async getPestcontrolTable() {
      const res = await getPestcontrolTable();
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: baseImgUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
    },
    // 闭店安检
    async getCloseshopTable() {
      const res = await getCloseshopTable();
      this.tableSource = res.data.data[0].data;
    },
    // 获取供应商资质管理
    async getSupplierTables() {
      const res = await getSupplierTable();
      this.showScore = res.data?.data?.data[0]?.show_score;
      this.showFoodimages = res.data?.data?.data[0]?.show_food_images;

      var SupplierTables = res.data.data?.data.map((item) => {
        if (!item.business_images.includes("http")) {
          return {
            ...item,
            business_images: baseImgUrl + item.business_images,
            arr_food_images: baseImgUrl + item.arr_food_images,
            produce_images: baseImgUrl + item.produce_images,
          };
        } else {
          return item;
        }
      });
      this.suppList = SupplierTables;
      if (!this.allTabsDate) {
        this.allTabsDate = {}; // 初始化
      }
      this.allTabsDate["供应商资质管理"] = SupplierTables;
    },
    // 处理价格公式逻辑
    async getPriceAndInitializeTable() {
      const res = await getPriceView();
      let dataInfo = [];
      let listInfo = [];
      Object.entries(res.data.data)?.forEach(([key, values]) => {
        if (key !== "") {
          const room = values.room;
          const list = values.list;
          if (room) {
            dataInfo.push(room);
          }
          if (list) {
            listInfo.push(list);
          }
        }
      });

      const datas = { tableTitle: dataInfo, tableDate: res.data.data };

      //初始化表格数据
      const allTableDate = [];
      // 遍历datas中的数据，更新gearopening属性并合并到allTableDate中
      Object.keys(datas?.tableDate)?.forEach((key) => {
        const list = datas.tableDate[key].list.map((item) => ({
          ...item,
          gearOpening: key,
        }));
        allTableDate.push(...list);
      });
      //设置表格数据
      this.allTabsDate = {
        价格公式: [...allTableDate],
      };
    },
    // 体温检测
    async getDDUserTemp() {
      const res = await getDDUserTemp();
      if (!this.allTabsDate) {
        this.allTabsDate = {}; // 初始化
      }
      this.allTabsDate["体温检测"] = res.data.data.data;
    },
    // 体温检测
    async getMorningCheck() {
      const res = await getMorningCheck();
      if (!this.allTabsDate) {
        this.allTabsDate = {}; // 初始化
      }
      this.allTabsDate["每日晨检"] = res.data.data;
    },
    // 智能晨检仪
    async getMorningCheckByMachine() {
      const res = await getMorningCheckByMachine();
      const dataArray = res?.data?.data?.data;

      let processedArray = [];
      if (Array.isArray(dataArray) && dataArray.length > 0) {
        processedArray = dataArray.map((item) => ({
          ...item, // 保留其他字段
          // 处理 health 字段拼接逻辑
          health: item.picture_recognition_content
            ? `${item.health || ""}（${item.picture_recognition_content}）`
            : item.health || "", // 若不需要默认值可移除 `|| ""`
          // 处理 picture_img 逻辑
          picture_img: [
            item.picture_img || "",
            item.picture_back_img || "",
          ].filter(Boolean),
        }));
      }

      if (!this.allTabsDate) {
        this.allTabsDate = {};
      }
      // 将修改后的对象数组存入
      this.allTabsDate["智能晨检仪"] = processedArray;
    },
    // 农残记录
    async getPesticideheckTable() {
      const res = await getPesticideheck();
      var Pesticideheck = res.data.data.data.map((item) => {
        if (!item.check_images.includes("http")) {
          return {
            ...item,
            check_images: baseImgUrl + item.check_images,
          };
        } else {
          return item;
        }
      });
      if (!this.allTabsDate) {
        this.allTabsDate = {}; // 初始化
      }
      this.allTabsDate["农残检测"] = Pesticideheck;
    },
    // 智能农残检测仪
    async getPesticideBymachineData() {
      const res = await getPesticideBymachine();
      var PesticideBymachine = res.data.data.data.map((item) => {
        // item里的imgs是一个数组，需要遍历判断，如果不包含http就拼接baseImgUrl
        if (item?.imgs && item?.imgs[0] && !item?.imgs[0].includes("http")) {
          item.imgs = item?.imgs.map((image) =>
            image.includes("http") ? image : baseImgUrl + image
          );
        }
        return item;
      });

      if (!this.allTabsDate) {
        this.allTabsDate = {}; // 初始化
      }
      this.allTabsDate["智能农残检测仪"] = PesticideBymachine;
    },
    // 仓库台账列表
    async getTruckTzListTable() {
      const res = await getERPTruckTzList({ from: "home" });
      this.show_price = res.data.data[1].show_price;
      this.allTabsDate["产品入库溯源记录"] = res.data.data[0].data.map(
        (item) => {
          const imageKeys = [
            "business_license_images_arr",
            "license_images_arr",
            "purchase_voucher_images_arr",
            "product_report_images_arr",
            "other_report_images_arr",
          ];

          imageKeys?.forEach((key) => {
            if (item[key] && item[key][0] && !item[key][0].includes("http")) {
              item[key] = item[key].map((image) =>
                image.includes("http") ? image : baseImgUrl + image
              );
            }
          });

          return item;
        }
      );
    },
    // 消毒记录
    async getXdjlTable() {
      const res = await getXdjl();
      this.allTabsDate["消毒记录"] = res.data.data.data.map((item) => {
        if (item.pic_images && item?.pic_images.includes("http")) {
          item.pic_images = baseImgUrl + item.pic_images;
        }
        return item;
      });
    },
    // 食品添加剂
    async getfoodsTable() {
      const res = await getFoodTjj();
      this.allTabsDate["食品添加剂"] = res.data.data[0].data;
    },
    // 食品保质期
    async getFogetFoodbzqodTjjTable() {
      const res = await getFogetFoodbzqodTjj();
      this.allTabsDate["食品保质期"] = res.data.data[0].data.map((item) => {
        return {
          ...item,
          pic_image: item.pic_image ? baseImgUrl + item.pic_image : "",
          make_date: item.make_date.substring(0, 16),
          due_date: item.due_date.substring(0, 16),
        };
      });
    },
    // 整改记录
    async getProblemListData() {
      const res = await getProblemList(this.requestData);
      this.total = res.data.data[0].total;
      this.allTabsDate["整改记录"] = res.data.data[0].data.map((item) => {
        const updatedImagesArr = item.images_arr.map((key) => {
          return key.includes("http") ? key : baseImgUrl + key;
        });
        if (!item.feedback_images) {
          console.log("item.feedback_images is null");
        } else {
          // 将逗号分隔的字符串转换为数组
          var imagesArray = item?.feedback_images.split(",");
          // 遍历数组，判断每个元素是否包含http，如果不包含则添加基础路径
          var feedbackImages = imagesArray.map((image) => {
            return image.includes("http") ? image : baseImgUrl + image;
          });
        }

        return {
          ...item,
          images_arr: updatedImagesArr,
          feedback_images: feedbackImages,
          created_time: item.created_time.slice(0, 16),
        };
      });
      this.pageTotal = res.data.data[0].total;
    },
    // 获取供应商档案
    async getTruckTzLists() {
      const res = await getTruckTzList(this.requestData);
      this.allTabsDate["每日入库台账"] = res.data.data[0].data.map((item) => {
        const imageKeys = [
          "business_license_images_arr",
          "license_images_arr",
          "purchase_voucher_images_arr",
          "product_report_images_arr",
          "other_report_images_arr",
          "balance_images_arr",
        ];

        imageKeys?.forEach((key) => {
          if (item[key] && item[key][0] && !item[key][0].includes("http")) {
            item[key] = item[key].map((image) =>
              image.includes("http") ? image : baseImgUrl + image
            );
          }
        });
        return item;
      });
    },
    // 新增
    // 添加内容格式化方法
    formatCellContent(row, field) {
      const value = row[field];
      if (!value) return "";

      if (typeof value === "string") {
        return value.replace(/\r\n/g, "<br>");
      }

      if (field === "due_days" && value >= 0) {
        return `${value}天`;
      }

      return value;
    },
    formatHealthContent(row, value) {
      if (value === "health") {
        return `${row[value]}`;
      }
      return row[value]; // 其他字段保持原样
    },
    startAutoPlay() {
      this.timer = setInterval(() => {
        this.currentPage = (this.currentPage + 1) % this.chunkedData.length;
      }, 8000);
    },
    pause() {
      clearInterval(this.timer);
    },
    resume() {
      this.startAutoPlay();
    },
  },
  computed: {
    groupedSuppList() {
      // 将一维数组转为二维数组，每组2个元素
      const chunkSize = 3;
      return this.suppList.reduce((acc, _, i) => {
        if (i % chunkSize === 0) {
          acc.push(this.suppList.slice(i, i + chunkSize));
        }
        return acc;
      }, []);
    },
    chunkedData() {
      const chunks = [];
      if (this.currentTable == "产品入库溯源记录") {
        for (let i = 0; i < this.tableSource?.length; i += 6) {
          chunks.push(this.tableSource.slice(i, i + 6));
        }
      } else {
        for (let i = 0; i < this.tableSource?.length; i += 11) {
          chunks.push(this.tableSource.slice(i, i + 11));
        }
      }
      return chunks;
    },
    healthStyle() {
      return {
        color: "red",
        fontWeight: "bold",
      };
    },
  },
  async mounted() {
    await this.getCustomImgs();
    this.startAutoPlay();
    this.currentIndex++;
    // 每隔2分钟调用 handleClick 方法
    this.intervalId2 = setInterval(() => {
      const currentTab = this.tabList[this.currentIndex];
      if (((this.currentIndex + 1) % this.tabList.length) - 1 == -1) {
        var INDEX = this.tabList.length - 1;
      } else {
        var INDEX = ((this.currentIndex + 1) % this.tabList.length) - 1;
      }
      this.handleClick(currentTab, INDEX);
      // 更新 currentIndex，循环遍历 tabList
      this.currentIndex = (this.currentIndex + 1) % this.tabList.length;
      this.activeName = currentTab.name;
    }, 3 * 60 * 1000); // 3分钟间隔3 * 60 * 1000
  },
  beforeDestroy() {
    clearInterval(this.intervalId2);
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.right-ul::v-deep .el-table--scrollable-y .el-table__body-wrapper {
  overflow-y: hidden !important;
}
/* 覆盖轮播箭头样式 */
.right-ul::v-deep .el-carousel__arrow {
  width: 35px;
  height: 35px;
  margin-top: 100px;
  background: rgba(24, 144, 255, 0.35) !important;
  backdrop-filter: blur(2px);
  border: 2px solid #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  // transition: all 0.3s ease;
}

/* 悬停效果 */
.right-ul::v-deep .el-carousel__arrow:hover {
  background: rgba(24, 144, 255, 0.9) !important;
  // transform: scale(1.1);
  box-shadow: 0 6px 16px rgba(24, 144, 255, 0.4);
}

/* 箭头图标颜色 */
.right-ul::v-deep .el-carousel__arrow i {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

/* 左右按钮位置微调 */
.right-ul::v-deep .el-carousel__arrow--left {
  left: 20px;
}

.right-ul::v-deep .el-carousel__arrow--right {
  right: 20px;
}
.tabs {
  flex-grow: 1;
  background-color: transparent;
  border: 2px solid rgb(18, 139, 255);
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: bolder;
}
.tabs:active {
  border-color: #a27b67 !important;
}
::v-deep .el-tabs__item.is-active {
  color: #409eff !important;
}
::v-deep .el-carousel__item {
  height: 545px;
}
::v-deep .right-ul .el-image__inner {
  height: 525px !important;
}
.left-three-item {
  transition: all 0.5s ease; /* 过渡动画效果 */
}
</style>
<style scoped>
.el-image-viewer__mask {
  opacity: 1 !important;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.8),
    rgba(20, 20, 20, 0.9)
  ) !important;
}

::v-deep .right-ul .right-li .el-image-viewer__img {
  border-radius: 15px !important;
}
/*  */
.carousel-tables {
  position: relative;
  overflow: hidden;
  width: 1060px;
  height: 520px;
  text-align: center;
  padding: 10px 0;
}

.carousel-slider {
  display: flex;
  transition: transform 0.1s ease-out 0.2s;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
  height: 530px;
  /* position: relative; */
  background-color: rgba(163, 160, 169, 0.4);
}
/* 全局加载层样式 */
.global-loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(64, 158, 255);
  z-index: 2000;
}

.el-icon-loading {
  font-size: 40px;
  margin-bottom: 16px;
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
