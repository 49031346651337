import { render, staticRenderFns } from "./temperatureAndHumidity.vue?vue&type=template&id=cf3c9c1e&scoped=true"
import script from "./temperatureAndHumidity.vue?vue&type=script&lang=js"
export * from "./temperatureAndHumidity.vue?vue&type=script&lang=js"
import style0 from "./temperatureAndHumidity.vue?vue&type=style&index=0&id=cf3c9c1e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf3c9c1e",
  null
  
)

export default component.exports