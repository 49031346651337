<template>
  <div class="goToTemperHumidity">
    <ChartBox :title="shop_name.includes('鑫晟') ?' 温湿度燃气监控':'温湿度监控'" :tip="true">
      <div class="temperContainer">
        <div
          class="tempItem"
          v-for="(item, index) in locationTempList"
          :key="index"
          v-if="locationTempList.length < 3"
        >
          <div class="location">{{ item.name }}</div>
          <!-- 温度 -->
          <div class="temper">
            <!-- 正常 -->
            <img
              v-if="item.temp_status == '正常'"
              src="../assets/image/normal1.png"
            />
            <!-- 警告 -->
            <img
              v-if="item.temp_status == '异常'"
              src="../assets/image/abnormal1.png"
            />
            <!-- 报警 -->
            <img
              v-if="item.temp_status == '警告'"
              src="../assets/image/warn1.png"
            />

            <img
              v-if="item.name == '大灶' && item.status == '正常'"
              src="../assets/image/ch4.png"
              style="margin-right: 15px; margin-top: 50px"
            />
            <!-- 警告 -->
            <img
              v-if="item.name == '大灶' && item.status == '异常'"
              src="../assets/image/ch4-2.png"
              style="margin-right: 15px; margin-top: 50px"
            />
            <!-- 报警 -->
            <img
              v-if="item.name == '大灶' && item.status == '警告'"
              src="../assets/image/ch4-3.png"
              style="margin-right: 15px; margin-top: 50px"
            />
            <img
              v-if="item.name == '后厨' && item.status == '正常'"
              src="../assets/image/normalPPM.png"
              style="margin-right: 15px; margin-top: 50px"
            />
            <!-- 警告 -->
            <img
              v-if="item.name == '后厨' && item.status == '异常'"
              src="../assets/image/PPM2.png"
              style="margin-right: 15px; margin-top: 50px"
            />
            <!-- 报警 -->
            <img
              v-if="item.name == '后厨' && item.status == '警告'"
              src="../assets/image/PPM.png"
              style="margin-right: 15px; margin-top: 50px"
            />
            <div class="temperNum" v-if="item.temp">{{ item.temp }}℃</div>
            <div
              class="temperNum"
              style="margin-top: 50px"
              v-if="item.name == '大灶'"
            >
              {{ item.val }} %LEL
            </div>
            <div
              class="temperNum"
              style="margin-top: 50px"
              v-if="item.name == '后厨'"
            >
              {{ item.val }} PPM
            </div>
          </div>
          <!-- 湿度 -->
          <div class="water">
            <!-- 正常 -->
            <img
              v-if="item.wet_status == '正常'"
              src="../assets/image/fullwater.png"
            />
            <!-- 警告 wet_status -->
            <img
              v-if="item.wet_status == '异常'"
              src="../assets/image/warter2.png"
            />
            <!-- 报警 -->
            <img
              v-if="item.wet_status == '警告'"
              src="../assets/image/warter3.png"
            />
            <div class="waterNum" v-if="item.wet">{{ item.wet }}%</div>
          </div>
        </div>
        <el-carousel
          style="width: 100%"
          height="230px"
          indicator-position="none"
          v-if="locationTempList.length > 2"
        >
          <el-carousel-item
            v-for="(item, index) in locationTempList2"
            :key="index"
            style="display: flex"
          >
            <div class="tempItem" v-for="(i, index) in item" :key="index">
              <div class="location">{{ i.name }}</div>
              <!-- 温度 -->
              <div class="temper">
                <!-- 正常 -->
                <img
                  v-if="i.temp_status == '正常'"
                  src="../assets/image/normal1.png"
                />
                <!-- 警告 -->
                <img
                  v-if="i.temp_status == '异常'"
                  src="../assets/image/abnormal1.png"
                />
                <!-- 报警 -->
                <img
                  v-if="i.temp_status == '警告'"
                  src="../assets/image/warn1.png"
                />
                <img
                  v-if="i.name.includes('大灶') && i.status == '正常'"
                  src="../assets/image/ch4.png"
                  style="margin-right: 15px; margin-top: 50px"
                />
                <!-- 警告 -->
                <img
                  v-if="i.name.includes('大灶') && i.status == '异常'"
                  src="../assets/image/ch4-2.png"
                  style="margin-right: 15px; margin-top: 50px"
                />
                <!-- 报警 -->
                <img
                  v-if="i.name.includes('大灶') && i.status == '警告'"
                  src="../assets/image/ch4-3.png"
                  style="margin-right: 15px; margin-top: 50px"
                />
                <img
                  v-if="i.name.includes('后厨') && i.status == '正常'"
                  src="../assets/image/normalPPM.png"
                  style="margin-right: 15px; margin-top: 50px"
                />
                <!-- 警告 -->
                <img
                  v-if="i.name.includes('后厨') && i.status == '异常'"
                  src="../assets/image/PPM2.png"
                  style="margin-right: 15px; margin-top: 50px"
                />
                <!-- 报警 -->
                <img
                  v-if="i.name.includes('后厨') && i.status == '警告'"
                  src="../assets/image/PPM.png"
                  style="margin-right: 15px; margin-top: 50px"
                />
                <div class="temperNum" v-if="i.temp">{{ i.temp }}℃</div>
                <div
                  class="temperNum"
                  style="margin-top: 50px"
                  v-if="i.name.includes('大灶')"
                >
                  {{ i.val }} %LEL
                </div>
                <div
                  class="temperNum"
                  style="margin-top: 50px"
                  v-if="i.name.includes('后厨')"
                >
                  {{ i.val }} PPM
                </div>
              </div>
              <!-- 湿度 -->
              <div class="water">
                <!-- 正常 -->
                <img
                  v-if="i.wet_status == '正常'"
                  src="../assets/image/fullwater.png"
                />
                <!-- 警告 wet_status -->
                <img
                  v-if="i.wet_status == '异常'"
                  src="../assets/image/warter2.png"
                />
                <!-- 报警 -->
                <img
                  v-if="i.wet_status == '警告'"
                  src="../assets/image/warter3.png"
                />
                <div class="waterNum" v-if="i.wet">{{ i.wet }}%</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </ChartBox>
  </div>
</template>

<script>
import ChartBox from "../components/chartBox.vue";
import { getIotInfoV2 } from "../api/api";
import { baseImgUrl } from "../utils/request";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      locationTempList: [],
      locationTempList2: [],
    };
  },
  components: {
    ChartBox,
  },
  mixins: [myMixin],
  mounted() {
    this.getIotInfoV();
  },
  methods: {
    // 获取地点
    async getIotInfoV() {
      const res = await getIotInfoV2();      
      var newArr = [];
      if (res.data.data.temp && res.data.data.temp.length > 0) {
        newArr = [...res.data.data.temp];
      }
      if (res.data.data.jiawan && res.data.data?.jiawan.length > 0) {
        newArr = [...newArr, ...res.data.data?.jiawan];
      }
      if (res.data.data.smog && res.data.data?.smog.length > 0) {
        newArr = [...newArr, ...res.data.data.smog];
      }
      this.locationTempList = newArr;
      
      // this.locationTempList是个数组，如果长度大于2，就把里面的item，每两个放进一个数组，就成了一个二维数组
      if (this.locationTempList.length > 2) {
        this.locationTempList2 = this.locationTempList.reduce(
          (prev, cur, index) => {
            const curIndex = Math.floor(index / 2);
            if (!prev[curIndex]) {
              prev[curIndex] = [];
            }
            prev[curIndex].push(cur);
            return prev;
          },
          []
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 温湿度监控
.box-left-two {
  .temperContainer {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    color: white;

    .tempItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      .location {
        height: 50px;
        line-height: 50px;
      }

      .temper {
        display: flex;
        align-items: center;
        height: 80px;

        img {
          margin-right: 4px;
          width: 40px;
          height: 45px;
          margin-left: 5px;
        }
      }

      .water {
        display: flex;
        align-items: center;
        height: 80px;
        img {
          margin-right: 3px;
        }
      }
    }
  }
}
</style>
