<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <!-- 查看大图容器 -->
      <div class="imgwrap" v-if="isShowImg">
        <img class="next" src="../assets/image/next.png" @click="nextImage()" />
        <img class="up" src="../assets/image/up.png" @click="upImage()" />
        <img :src="bigPic" alt="点击关闭" @click="viewBack" />
      </div>
      <!-- 标题栏部分 -->
      <AiHeader ref="mychild" :inner="true" :AiHeaderQuery="AiHeaderQuery"></AiHeader>
      <div class="box-container">
        <aside>
          <!-- 学校信息 -->
          <div class="box-left-one" @click="handleFullScreen">
            <ChartBox title="食堂信息">
              <div
                class="logo"
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 20px;
                "
              >
                <img
                  :src="shop_img"
                  style="
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    margin-left: 5px;
                    margin-top: 50px;
                  "
                />
                <div style="color: white">
                  <div
                    class="phone"
                    style="margin: 10px 0; width: 240px; font-weight: bolder"
                  >
                    <!-- <span>门店名称：</span> -->
                    <span>{{ shop_name }}</span>
                  </div>
                  <div class="phone" style="margin: 10px 0">
                    <span>负 责 人 ：</span>
                    <span>{{ shop_owner }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>联系电话：</span>
                    <span>{{ shop_phone }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>餐厅面积：</span>
                    <span>{{ area }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>就餐人数：</span>
                    <span>{{ users_amount }}</span>
                  </div>
                  <div
                    class="shop_address"
                    style="line-height: 1.5; width: 240px"
                  >
                    <span>餐厅地址：</span>
                    <span>{{ shop_address }}</span>
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>

          <!-- 温湿度监控 -->
          <div class="box-left-two" @click="toTableDetail">
            <ChartBox title="温湿度监控" :tip="true">
              <div class="temperContainer">
                <div
                  class="tempItem"
                  v-for="(item, index) in locationTempList"
                  :key="index"
                >
                  <div class="location">{{ item.name }}</div>
                  <!-- 温度 -->
                  <div class="temper">
                    <!-- 正常 -->
                    <img
                      v-if="item.temp_status == '正常'"
                      src="../assets/image/normal1.png"
                      style="width: 40px; height: 45px; margin-left: 10px"
                    />
                    <!-- 警告 -->
                    <img
                      v-if="item.temp_status == '异常'"
                      src="../assets/image/abnormal1.png"
                      style="width: 40px; height: 45px; margin-left: 10px"
                    />
                    <!-- 报警 -->
                    <img
                      v-if="item.temp_status == '警告'"
                      src="../assets/image/warn1.png"
                      style="width: 40px; height: 45px; margin-left: 10px"
                    />
                    <div class="temperNum">{{ item.temp }}℃</div>
                  </div>
                  <!-- 湿度 -->
                  <div class="water">
                    <!-- 正常 -->
                    <img
                      style="width: 40px; height: 45px; margin-left: 10px"
                      v-if="item.wet_status == '正常'"
                      src="../assets/image/fullwater.png"
                    />
                    <!-- 警告 wet_status -->
                    <img
                      style="width: 40px; height: 45px; margin-left: 10px"
                      v-if="item.wet_status == '异常'"
                      src="../assets/image/warter2.png"
                    />
                    <!-- 报警 -->
                    <img
                      style="width: 40px; height: 45px; margin-left: 10px"
                      v-if="item.wet_status == '警告'"
                      src="../assets/image/warter3.png"
                    />
                    <div class="waterNum">{{ item.wet }}%</div>
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>

          <!-- 健康证展示 -->
          <div class="box-left-three" @click="gotoHealthyList">
            <ChartBox title="健康证展示" more="更多">
              <el-carousel height="230px" indicator-position="none">
                <el-carousel-item
                  v-for="(item, index) in healthList"
                  :key="index"
                  style="display: flex; justify-content: center"
                >
                  <div>
                    <img
                      :src="
                        item.pic_image +
                        '?x-oss-process=image/resize,w_280,m_lfit'
                      "
                      alt=""
                      style="width: 270px; height: 130px; margin-top: 10px"
                    />
                    <div>
                      <div
                        style="
                          font-size: 12px;
                          border: 1px solid rgb(86, 181, 249);
                          display: inline-block;
                          padding: 5px;
                          border-radius: 5px;
                          color: rgb(86, 181, 249);
                          margin: 5px 20px 10px 0;
                        "
                      >
                        健康证有效
                      </div>
                      <div
                        style="
                          font-size: 12px;
                          border: 1px solid rgb(86, 181, 249);
                          display: inline-block;
                          padding: 5px;
                          border-radius: 5px;
                          color: rgb(86, 181, 249);
                        "
                      >
                        到期日期：{{ item.end_date }}
                      </div>
                    </div>
                    <div style="color: white; font-size: 12px">
                      <div class="heaName">姓名：{{ item.name }}</div>
                      <div class="heaName" style="margin: 5px 0">
                        健康证编号：{{ item.code }}
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </ChartBox>
          </div>
        </aside>

        <section>
          <!-- 证件表格 -->
          <div class="center-buttom">
            <TabPane :tableTeight="tableTeight" :tabList2="tabList"></TabPane>
          </div>
          <!-- 信息展示 -->
          <div class="healthCertificate">
            <ChartBox title="信息展示">
              <div
                class="carouselBox"
                style="
                  display: flex;
                  justify-content: space-around;
                  overflow-x: scroll;
                "
              >
                <div v-for="(item, index) in dataList" :key="index">
                  <div style="margin-top: 4px">
                    <img
                      @click="viewinfoLargeImage(item, index)"
                      :src="item + '?x-oss-process=image/resize,w_330,m_lfit'"
                      alt=""
                      style="width: 330px; height: 216px; border-radius: 5px"
                    />
                  </div>
                </div>
              </div>
            </ChartBox>
          </div>
        </section>

        <article>
          <!-- 店长助手 -->
          <div class="box-left-whea"  @click="toAssistantManager">
            <StoreManager style="width: 100%;height: 100%;"></StoreManager>
          </div>
          <!-- 视频监控 -->
          <div class="center-top">
            <ChartBox title="视频监控">
              <template v-slot:selectBox>
                <!-- <div class="title-box">
                    <el-checkbox
                      @change="changeCheckbox"
                      v-model="checked"
                      style="color: #fff; margin-right: 10px"
                      >本地监控</el-checkbox
                    >
                  </div> -->
                <el-dropdown size="mini" split-button type="primary">
                  <span class="el-dropdown-link">
                    {{ value2 }}
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(item, index) in option2"
                      :key="index"
                      @click.native="changeValue2(item.label)"
                    >
                      <span> {{ item.label }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>

              <div class="one">
                <iframe
                  v-if="checked"
                  src="/iframe.html"
                  id="iframe"
                  frameborder="0"
                  scrolling="no"
                ></iframe>
                <el-carousel
                  v-else-if="isCarousel"
                  class="right-ul"
                  height="230px"
                  indicator-position="none"
                >
                  <el-carousel-item
                    v-for="(item, index) in currentImgList"
                    :key="index"
                  >
                    <div class="right-li">
                      <img
                        :src="item"
                        style="width: 100%; height: 240px; overflow: hidden"
                        @error="handleBImageError"
                        @click="viewLargeVideo(item, index)"
                      />
                    </div>
                  </el-carousel-item>
                </el-carousel>

                <img
                  style="width: 100%; height: 100%"
                  :src="currentImg || defaultImgB"
                  v-else
                  @error="handleBImageError"
                />
              </div>
            </ChartBox>
          </div>

          <!-- 抓拍部分 -->
          <div class="box-right-three">
            <ChartBox title="AI预警抓拍">
              <div v-if="!Snap.length > 0">
                <img
                  style="height: 232px; width: 100%"
                  src="./../assets/image/NoImg.png"
                  alt=""
                />
              </div>
              <el-carousel
                class="right-ul"
                height="250px"
                indicator-position="none"
                v-else
              >
                <el-carousel-item v-for="(item, index) in Snap" :key="index">
                  <div class="right-li">
                    <img
                      class="img"
                      :src="item.img"
                      alt=""
                      @click="viewLargeImage(item.img, index)"
                    />
                    <div>
                      <div class="Title">{{ item.title }}</div>
                      <div class="time">抓拍时间：{{ item.time }}</div>
                      <div class="name">
                        抓拍地点：
                        <div>{{ item.address }}</div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </ChartBox>
          </div>
        </article>
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import AiHeader from "../components/aiHeader.vue";
import TabPane from "../components/tabPane.vue";
import StoreManager from "../components/storeManager.vue"
import { baseImgUrl } from "../utils/request";
import {
  getAiBox,
  getShopImage,
  getSuggest,
  getIotInfoV2,
  getshowImgs,
  getHealthTable,
  getBlocks,
} from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      AiHeaderQuery:false,
      isLargeImage: false,
      isPic: true,
      bigPicIndex: 0,
      dataList: [],
      cleanUpMemory: null,
      healthList: [],
      locationTempList: [], //温湿度
      suggestImg: "", //投诉意见反馈
      tableTeight: "530", //证件表格高度
      tabList: [],
      bigPic: "",
      isLocal: "", //是否是本地
      index: 0,
      videoList: [],
      currentImgList: [],
      defaultImg: "",
      currentVideo: [],
      imageRequestData: {
        page: 1,
      },
      indexs: -1,
      isShowImg: false,
      datas: [],
      Snap: [],
    };
  },
  mixins: [myMixin],
  methods: {
    // 去温湿度折线图页面
    toTableDetail() {
      this.$router.push({ path: "/IoTMonitoring" });
    },
    handleFullScreen() {
      this.$refs.mychild.handleFullScreen();
    },    
    toAssistantManager() {
      this.$router.push({ path: "/StoreManager" });
    },
    out() {
      this.$refs.mychild.out();
    },
    // 跳转到健康证查询页面
    gotoHealthyList() {
      this.$router.push({ path: "/healthyList" });
    },
    handleImageError() {
      this.defaultImg = "http://aibigscreen.ufoodlife.cn/img/nosignal1.png"; // 图片加载错误时，将src属性绑定到默认的无信号图片路径
    },
    // 视频监控的checkbox的change事件
    changeCheckbox(e) {
      this.index = 0;
      this.indexs = -1;
      this.checked = e;
      if (this.checked) {
        this.currentVideo = [];
      } else {
        this.currentVideo = [];
        this.getAiBox();
      }
    },
    // 播放异常 重新拉流
    pullFlow() {},
    // 播放事件
    play(a) {
      this.index++;
      if (a.children) {
        this.index = 0;
        this.indexs = -1;
        Array.prototype.push.apply(this.videoList, a.children);
      } else {
        this.indexs++;
        this.currentImg = !this.checked ? a.channel_image : a.flv_url;
        if (this.index < 5) {
          this.currentVideo.splice(this.index - 1, 1, this.currentImg);
        } else {
          this.indexs = 3;
          this.currentVideo.splice(
            this.currentVideo.length - 1,
            1,
            this.currentImg
          );
        }
      }
    },
    // 树状节点
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    changIndex(e, i) {
      this.indexs = e;
      this.index = e;
    },
    // 查看预警抓拍大图
    viewLargeImage(url, index) {
      this.isLargeImage = false;
      this.isPic = true;
      this.bigPicIndex = index;
      this.isShowImg = true;
      this.bigPic = url;
    },
    // 查看大图(视频监控)
    viewLargeVideo(url, index) {
      this.isLargeImage = false;
      this.isPic = false;
      this.bigPicIndex = index;
      this.isShowImg = true;
      this.bigPic = url;
    },
    // 信息展示查看大图
    viewinfoLargeImage(url, index) {
      this.isPic = false;
      this.isLargeImage = true;
      this.bigPicIndex = index;
      this.isShowImg = true;
      this.bigPic = url;
    },
    // 上一张
    upImage() {
      if (this.bigPicIndex > 0 && this.isPic && !this.isLargeImage) {
        this.bigPicIndex--;
        this.bigPic = this.Snap[this.bigPicIndex].img;
      } else if (this.bigPicIndex == 0 && this.isPic && !this.isLargeImage) {
        this.bigPicIndex = this.Snap.length - 1;
        this.bigPic = this.Snap[this.bigPicIndex].img;
      } else if (this.bigPicIndex > 0 && !this.isPic && !this.isLargeImage) {
        this.bigPicIndex--;
        this.bigPic = this.currentImgList[this.bigPicIndex];
      } else if (this.bigPicIndex == 0 && !this.isPic && !this.isLargeImage) {
        this.bigPicIndex = this.currentImgList.length - 1;
        this.bigPic = this.currentImgList[this.bigPicIndex];
      } else if (this.bigPicIndex > 0 && this.isLargeImage && !this.isPic) {
        this.bigPicIndex--;
        this.bigPic = this.dataList[this.bigPicIndex];
      } else if (this.bigPicIndex == 0 && this.isLargeImage && !this.isPic) {
        this.bigPicIndex = this.dataList.length - 1;
        this.bigPic = this.dataList[this.bigPicIndex];
      }
    },
    // 下一张
    nextImage() {
      if (
        this.bigPicIndex < this.Snap.length - 1 &&
        this.isPic &&
        !this.isLargeImage
      ) {
        this.bigPicIndex++;
        this.bigPic = this.Snap[this.bigPicIndex].img;
      } else if (
        this.bigPicIndex == this.Snap.length - 1 &&
        this.isPic &&
        !this.isLargeImage
      ) {
        this.bigPicIndex = 0;
        this.bigPic = this.Snap[this.bigPicIndex].img;
      } else if (
        this.bigPicIndex < this.currentImgList.length - 1 &&
        !this.isPic &&
        !this.isLargeImage
      ) {
        this.bigPicIndex++;
        this.bigPic = this.currentImgList[this.bigPicIndex];
      } else if (
        this.bigPicIndex == this.currentImgList.length - 1 &&
        !this.isPic &&
        !this.isLargeImage
      ) {
        this.bigPicIndex = 0;
        this.bigPic = this.currentImgList[this.bigPicIndex];
      } else if (
        this.bigPicIndex < this.dataList.length - 1 &&
        this.isLargeImage &&
        !this.isPic
      ) {
        this.bigPicIndex++;
        this.bigPic = this.dataList[this.bigPicIndex];
      } else if (
        this.bigPicIndex == this.dataList.length - 1 &&
        this.isLargeImage &&
        !this.isPic
      ) {
        this.bigPicIndex = 0;
        this.bigPic = this.dataList[this.bigPicIndex];
      }
    },
    // 返回原图
    viewBack() {
      this.isShowImg = false;
    },
    // 获取设备状态和AI设备
    async getAiBox() {
      const res = await getAiBox();
      this.isLocal = res.data.data.aiboxInfo.is_local;
      // 树状结构
      this.datas = [
        {
          label: res.data.data.aiboxInfo.shop_name,
        },
      ];
      this.datas[0].children = res.data.data.channel.map((item, index) => {
        if (index < 4) {
          this.currentVideo.push(item.channel_image);
        }
        return {
          id: item.id,
          label: item.channel_name + "（" + item.channel_address + "）",
          flv_url: item.flv_url,
          channel_image: item.channel_image,
        };
      });
      this.option2 = this.datas[0].children;
      // 自动轮播图片数组
      this.option2.forEach((obj) => {
        // 检查对象中是否有 channel_image 属性
        if (!obj.channel_image?.includes("http")) {
          // 将 channel_image 属性的值添加到 currentImgList 数组中
          this.currentImgList.push(baseImgUrl + obj.channel_image);
        } else {
          this.currentImgList.push(obj.channel_image);
        }
      });
      this.value2 = this.option2[0].label;
      if (this.checked) {
        this.currentImg = this.option2[0].flv_url;
      } else {
        this.currentImg = this.option2[0].channel_image;
      }
      // this.setTwoChart();
    },
    // 获取抓拍图片
    async getImage() {
      const res = await getShopImage(this.imageRequestData);
      this.Snap = res.data.data.shopImgs.data.map((item, index) => {
        return {
          title: item.output_class_name,
          time: item.created_at,
          img: !item.alarm_image?.includes("http")
            ? baseImgUrl + item.alarm_image
            : item.alarm_image,
          address: item.channel_address,
          chid: item.chid,
        };
      });
    },
    // 获取客诉
    async getSuggests() {
      const res = await getSuggest();
      if (!res.data.data.imgs[0]?.includes("http")) {
        this.suggestImg = baseImgUrl + res.data.data.imgs[0];
      } else {
        this.suggestImg = res.data.data.imgs[0];
      }
    },
    // 获取地点
    async getIotInfoV() {
      const res = await getIotInfoV2();
      this.locationTempList = res.data.data.temp;
    },
    // 获取信息展示
    async getshowImgs() {
      const res = await getshowImgs({ from: "inner" });
      this.dataList = res.data.data.imgs.map((item) => {
        if (!item.includes("http")) {
          item = baseImgUrl + item;
        }
        return item;
      });
    },
    // 健康证信息展示
    async getHealthTable() {
      const res = await getHealthTable({
        order: "desc",
        orderby: "end_date",
      });
      this.healthList = res.data.data[0].data.map((item) => {
        // 判断对象中是否有 pic_image 属性
        if (!item.pic_image?.includes("http")) {
          item.pic_image = baseImgUrl + item.pic_image;
        }
        return item;
      });
    },
    // 获取大屏展示内容
    async getBlocksTable() {
      const res = await getBlocks({ from: "inner" });
      this.tabList = res.data.data.map((str) => {
        return { label: str };
      });
    },
  },
  components: {
    ChartBox,
    Chart,
    AiHeader,
    TabPane,
    StoreManager
  },
  created() {
    this.getBlocksTable();
    this.getshowImgs();
    this.getHealthTable();
    this.getAiBox();
    this.getImage();
    this.cleanUpMemory = setInterval(() => {
      this.getAiBox();
      this.getImage();
    }, 15 * 60 * 1000);
    this.getSuggests();
    this.getIotInfoV();
    // this.getShopChannelImg();
    // this.getTemperatures();
  },
  mounted() {
    document.title = "优食安数字食堂云平台";
    if(this.$route.query.from==="aiHeader"){
    this.AiHeaderQuery = true
  }
  },

  beforeDestroy() {
    clearInterval(this.cleanUpMemory);
    this.index = 0;
  },
};
</script>
<style lang="scss" scoped>
.carouselBox {
  margin: 0 auto;
  width: 100%;
  height: 230px;
  .carousel {
    width: 100%;
    height: 420px;
    .el-car-item {
      width: 100%;
      height: 420px;
      display: flex;
      .divSrc {
        width: 323px;
        height: 230px;
        background: #fff;
        margin-right: 46px;

        .img {
          width: 323px;
          height: 230px;
        }
        .title {
          width: 90%;
          height: 80px;
          line-height: 80px;
          margin: 0 auto;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #222222;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    ::v-deep .el-carousel__arrow {
      background: #99a9bf !important;
      display: block !important;
      margin-top: 65px;
    }
  }
  .el-car-item {
    width: 100%;
    display: flex;

    .img {
      width: 284px;
      height: 184px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
// 查看大图样式
.imgwrap {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 99;

  .next {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 30px;
    top: 50%;
  }
  .up {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 30px;
    top: 50%;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

// 滚动条样式
::-webkit-scrollbar-track-piece {
  background: rgb(1, 22, 55);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* 横向滚动条的大小 */
}

::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 20px;
}

::v-deep .el-dropdown .el-button-group {
  display: flex;
}
::v-deep .el-button-group > .el-button:not(:last-child) {
  width: 100px;
}
::v-deep .el-button {
  text-overflow: ellipsis;
  overflow: hidden;
}

.container {
  height: 100%;
  width: 100%;
  background: url("../assets/image/bg.577ccc4c.jpg");

  .box-left-one {
    .logo {
      img {
        width: 180px;
        height: 180px;
      }

      .head span,
      .phone span,
      .shop_address span {
        font-size: 15px;
      }
    }
  }
}

/* 中间 */
.box-container {
  min-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
  height: calc(100% - 130px);
  > section,
  > aside,
  > article {
    flex: 0 0 20%;
    width: 0;
    > * {
      height: 300px;
      & + * {
        margin-top: 20px;
      }
    }

    .center-buttom {
      box-sizing: border-box;
      height: 620px;
    }
  }
  > section {
    flex: 1;
    margin: 0 20px;
  }
}

.box-left-two {
  .temperContainer {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    color: white;

    .tempItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      .location {
        // width: 100%;
        height: 50px;
        line-height: 50px;
      }

      .temper {
        display: flex;
        align-items: center;
        height: 80px;

        img {
          margin-right: 4px;
        }
      }

      .water {
        display: flex;
        align-items: center;
        height: 80px;

        // margin-left: 8px;
        img {
          margin-right: 3px;
        }
      }
    }
  }
}

.box-left-three {
  overflow: hidden;
}

.center-top {
  .one {
    box-sizing: border-box;
    // flex: 1;
    width: 100%;

    margin: 0 auto;
    height: 100%;
    color: white;

    img {
      height: 260px;
      width: 350px;
    }
  }
}

.center-buttom {
  display: flex;
  justify-content: space-between;
  color: white;
  border: 2px solid rgb(34, 77, 128);
  border-radius: 10px;
  padding: 0 10px;
}

.box-right-three {
  height: 100%;

  .right-ul {
    display: flex;
    flex-direction: column;
    max-height: 98%;

    .right-li {
      display: flex;
      width: 94%;
      height: 100%;
      padding: 2%;
      background-image: url("../assets/image/img-backgroud.png");

      .Title {
        font-size: 16px;
        color: white;
        font-weight: bolder;
        margin-bottom: 8px;
      }

      .img {
        width: 72%;
        height: 95%;
        border-radius: 5px;
        margin-right: 10px;
      }

      .time,
      .name {
        font-size: 12px;
        color: white;
        margin-top: 15px;
      }
    }
  }
}

.currentBg {
  background: rgb(0, 228, 255) !important;
}

.noCurrentBg {
  color: white !important;
}
</style>
<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
// 表单格式
::v-deep .el-table {
  width: 98% !important;
  margin-left: 10px;
  transform: translateY(12px) scale(0.998);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.32);
  .el-table__body {
    height: 100%;
  }
}
// 表格单元格
::v-deep .box-container .el-table .el-table__cell {
  padding: 9px 7px;
  color: #333 !important;
  font-size: 14px;
  // background-color: rgba(255,255,255,0.1);
  background-color: rgba(163, 160, 169, 0.25);
}
// 表头
::v-deep .el-table th.el-table__cell.is-leaf {
  padding: 10px 0 !important;
  font-size: 16px;
  border: none !important;
  color: #333 !important;
  background-color: rgba(252, 244, 226, 0.3) !important;
}
::v-deep .box-container .el-tabs .el-tabs__item:not(:first-child) {
  color: #a27b67;
}

::v-deep .box-container .el-tabs .el-tabs__item:hover {
  color: #a27b67 !important;
}

.el-tabs__active-bar {
  background-color: #a27b67 !important;
}
.box-container .el-tabs {
  width: 100%;
  color: #fffff0;
}

.center-buttom .el-tab-pane .el-image {
  width: 40px;
  height: 40px;
}


// Card样式
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 50%;
  // display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.center-buttom .el-button {
  padding: 3px 4px;
}
</style>
