<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <dv-border-box-11 title="健康证管理">
        <div class="borderBox">
          <!-- 日期 -->
          <div
            class="dateTime"
            style="
              color: white;
              position: absolute;
              top: 80px;
              right: 150px;
              font-weight: bolder;
              font-size: 18px;
            "
          >
            {{ formattedDate }}
          </div>
          <div
            style="
              height: 13%;
              display: flex;
              align-items: flex-end;
              padding: 20px 155px 40px;
            "
          >
            <!-- 返回按钮 -->
            <i
              class="el-icon-d-arrow-left"
              style="
                color: aliceblue;
                font-size: 20px;
                position: absolute;
                top: 80px;
                left: 35px;
              "
              @click="goback"
              >返回</i
            >

            <div class="block" style="display: flex; align-items: center">
              <span style="color: white; font-weight: bolder">员工姓名：</span>
              <el-input
                style="width: 180px;margin-right: 20px;"
                @change="nameChange"
                placeholder="请输入员工姓名"
                v-model="requestData.search_key"
                clearable.el-button
              >
              </el-input>

              <el-button
                style="text-align: center"
                type="primary"
                icon="el-icon-search"
                @click="search"
                >人员查询</el-button
              >
            </div>
            <div style="color: white; margin-left: 20px; font-weight: bolder">
              <span>健康证到期日期：</span>
              <el-date-picker
                value-format="yyyy-MM-dd"
                @change="changeDate"
                v-model="value"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 380px; margin-right: 25px"
              >
              </el-date-picker>
            </div>
            <el-button
              type="primary"
              icon="el-icon-refresh-right"
              @click="clearAll"
              >重置</el-button
            >
            <div
              style="
                color: gray;
                font-weight: bolder;
                position: absolute;
                right: 150px;
              "
            >
              健康证总数：{{ total }}
            </div>
          </div>
          <!-- 升降序图标 -->
          <div
            style="position: absolute; top: 240px; z-index: 99; right: 362px"
            @click="changeAsc"
          >
            <img
              src="../assets/image/jiangxu.png"
              style="width: 25px; height: 25px"
              v-if="isAsc"
            />
            <img
              src="../assets/image/shengxu.png"
              style="width: 25px; height: 25px"
              v-else
            />
          </div>
          <div style="width: 85%; margin: 0 auto; height: 85%">
            <tableCom
              :tableTitle="tableTitle"
              :tableDatas="tableSource"
              :stockHeight="stockHeight"
              class="left-three-item"
            >
              <template #pic_image="{ scope }">
                <div>
                  <el-popover
                    placement="left-start"
                    width="800"
                    trigger="click"
                  >
                    <el-row>
                      <el-col>
                        <el-card>
                          <img
                            width="100%"
                            :src="scope.row.pic_image"
                            class="image"
                          />
                        </el-card>
                      </el-col>
                    </el-row>
                    <el-image
                      style="width: 70px; height: 50px"
                      :src="scope.row.pic_image"
                      slot="reference"
                    >
                      <div slot="error" class="image-slot"></div>
                    </el-image>
                  </el-popover>
                </div>
              </template>
            </tableCom>
          </div>
          <!-- <el-pagination
            :page-size="8"
            :current-page.sync="currentPage"
            @current-change="handleCurrentChange"
            style="position: absolute; right: 145px; bottom: 90px"
            background
            layout="prev, pager, next"
            :total="pageTotal"
          ></el-pagination> -->
        </div>
      </dv-border-box-11>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import { getHealthTable } from "../api/api";
import { baseImgUrl } from "../utils/request";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      total: "",
      isAsc: true,
      stockHeight: 760,
      currentPage: 1,
      pageTotal: 0,
      requestData: {
        start_date: "",
        end_date: "",
        page: 1,
        search_key: "",
        order: "asc",
        orderby: "end_date",
      },
      value: "",
      tableSource: [],
      tableTitle: [],
    };
  },
  mixins: [myMixin],
  methods: {
    changeAsc() {
      this.isAsc = !this.isAsc;
      this.requestData.order = this.isAsc ? "asc" : "desc";
      this.getHealthTables();
    },
    clearAll() {
      this.requestData = {
        start_date: "",
        end_date: "",
        page: 1,
        search_key: "",
      };
      this.value = "";
      this.getHealthTables();
    },
    handleCurrentChange(val) {
      this.requestData.page = val;
      this.getHealthTables();
      this.requestData.page = 1;
    },
    search() {
      this.getHealthTables();
    },
    // 健康证搜索框change事件
    nameChange(e) {
      this.requestData.search_key = e;
    },
    // 时间选择器change事件
    changeDate(e) {
      this.requestData.start_date = e[0];
      this.requestData.end_date = e[1];
      this.getHealthTables();
    },
    async getHealthTables() {
      const res = await getHealthTable(this.requestData);
      this.total = res.data.data[0].total;
      this.tableSource = res.data.data[0].data.map((item) => {
        return {
          ...item,
          pic_image: item.pic_image.includes("http")
            ? item.pic_image
            : baseImgUrl + item.pic_image,
        };
      });
      this.pageTotal = res.data.data[0].total;
    },
  },
  components: { ChartBox, Chart },
  created() {
    this.getHealthTables();
    this.tableTitle = [
      {
        name: "健康证",
        width: "250",
        value: "pic_image",
      },
      {
        name: "姓名",
        width: "220",
        value: "name",
      },
      {
        name: "健康证编号",
        width: "250",
        value: "code",
      },
      {
        name: "发证单位",
        width: "",
        value: "publish",
      },
      {
        name: "健康证到期日期",
        width: "250",
        value: "end_date",
      },
      {
        name: "到期日期提醒",
        width: "200",
        value: "due_days",
      },
    ];
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.container {
  width: 1920px;
  height: 1080px;
  background: url("../assets/image/bg.577ccc4c.jpg");
  .borderBox {
    height: 100%;
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table th.el-table__cell.is-leaf {
  height: 40px;
  border: none !important;
  color: white;
  font-size: 18px;
}
::v-deep .el-table td.el-table__cell {
  background-color: rgb(1, 20, 49) !important;
}
::v-deep .el-button--mini {
  font-size: 15px;
}
::v-deep .el-button {
  padding: 12px 10px;
}

.container .el-table__body-wrapper::-webkit-scrollbar {
  width: 0.375rem !important;
}
// 时间选择器样式
.container .el-range-editor.el-input__inner {
  background-color: rgb(1, 52, 92);
  color: white;
}
::v-deep .container .el-range-editor span {
  color: white;
  font-weight: bolder;
  margin: 0 10px;
}
::v-deep .container .el-date-editor .el-range-input {
  border-radius: 5px;
  margin-left: 5px;
}
</style>
