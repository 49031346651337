<template>
  <div>
    <ChartBox title="视频监控">
      <template v-slot:selectBox>
        <el-dropdown size="mini" split-button type="primary">
          <span class="el-dropdown-link">
            {{ value2 }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in option2"
              :key="index"
              @click.native="changeValue2(item.label)"
            >
              <span> {{ item.label }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <div class="one">
        <iframe
          v-if="checked"
          src="/iframe.html"
          id="iframe"
          frameborder="0"
          scrolling="no"
        ></iframe>
        <!-- <iframe
                  src="http://47.99.163.50:18080/#/play/wasm/ws%3A%2F%2F47.99.163.50%3A6080%2Frtp%2F41010500001110000001_41010500001310000104.live.flv"
                  width="350px"
                  height="210px"
                ></iframe> -->
        <el-carousel
          v-else-if="isCarousel"
          class="right-ul"
          height="230px"
          indicator-position="none"
        >
          <el-carousel-item
            v-for="(item, index) in currentImgList"
            style="height: 100%"
            :key="index"
          >
            <div class="right-li" style="height: 100%">
              <el-image
                class="describleImage"
                :src="item"
                style="height: 230px;width: 100%;"
                :preview-src-list="currentImgList"
              ></el-image>
            </div>
          </el-carousel-item>
        </el-carousel>

        <img
          style="width: 100%; height: 230px"
          :src="currentImg || defaultImgB"
          v-else
          @error="handleBImageError"
        />
      </div>
    </ChartBox>
  </div>
</template>

<script>
import ChartBox from "../components/chartBox.vue";
import { baseImgUrl } from "../utils/request";
// 引入混入
import { myMixin } from "../common/common.js";
import { getAiBox } from "../api/api";

export default {
  data() {
    return {
      currentImgList: [],
      isLocal: "", //是否是本地
      datas: [],
      cleanUpMemory: null,
      currentVideo: [],
    };
  },
  mixins: [myMixin],
  components: {
    ChartBox,
  },
  mounted() {},
  created() {
    this.getAiBox();
    this.cleanUpMemory = setInterval(() => {
      this.getAiBox();
    }, 15 * 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.cleanUpMemory);
  },
  methods: {
    // 获取设备状态和AI设备
    async getAiBox() {
      const res = await getAiBox();
      this.isLocal = res.data.data?.aiboxInfo.is_local;
      // 树状结构
      this.datas = [
        {
          label: res.data.data?.aiboxInfo.shop_name,
        },
      ];
      this.datas[0].children = res.data.data?.channel.map((item, index) => {
        if (index < 4) {
          this.currentVideo.push(item.channel_image);
        }
        return {
          id: item.id,
          label: item.channel_name + "（" + item.channel_address + "）",
          flv_url: item.flv_url,
          channel_image: item.channel_image,
        };
      });
      this.option2 = this.datas[0].children;

      // 自动轮播图片数组
      this.option2?.forEach((obj) => {
        // 检查对象中是否有 channel_image 属性
        if (!obj.channel_image?.includes("http")) {
          // 将 channel_image 属性的值添加到 currentImgList 数组中
          this.currentImgList.push(baseImgUrl + obj.channel_image);
        } else {
          this.currentImgList.push(obj.channel_image);
        }
      });
      this.value2 = this.option2 && this.option2[0]?.label;
      if (this.checked) {
        this.currentImg =  this.option2 && this.option2[0].flv_url;
      } else {
        this.currentImg = this.option2 && this.option2[0]?.channel_image;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 查看大图样式
.imgwrap {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 999;

  .next {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 30px;
    top: 50%;
  }
  .up {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 30px;
    top: 50%;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
