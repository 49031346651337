import {
  getTastingTable,
  getMenuTable,
  getRetentionTable,
  getWasteTable,
  getCzjxdTable,
  getHealthcheckTable,
  getCjxdTable,
  getArea8cleanTable,
  getPsgcleanTable,
  getCzjselfcheckTable,
  getColdstorecheckTable,
  getPestcontrolTable,
  getCloseshopTable,
  getSupplierTable,
  getPriceView,
  getDDUserTemp,
  getPesticideheck,
  getERPTruckTzList,
  getXdjl,
  getProblemList,
  getPesticideBymachine,
  getFoodTjj,
  getFogetFoodbzqodTjj,
  getCustomImgs,
  getTruckTzList,
  getBlocks,
  getshowImgs,
  getTaskRecordV2
} from "../api/api";

import { baseImgUrl } from "../utils/request";
export var myMixin = {
  data() {
    return {
      rolltimers: [],
      currentDate: new Date(), //日期
      // 食堂信息
      shop_name: "",
      shop_users_amount: "",
      shop_owner: "",
      shop_phone: "",
      shop_img: "",
      shop_address: "",
      area: "",
      users_amount: "",
      dialogVisible: false,
      
      checked: false, //视频流是否开启
      // 视频监控
      defaultImgB: "",
      isCarousel: true,
      value2: "",
      option2: [],
    };
  },
  methods: {
    handleBImageError() {
      this.defaultImgB = "http://aibigscreen.ufoodlife.cn/img/nosignal2.png"; // 图片加载错误时，将src属性绑定到默认的无信号图片路径
    }, //获取iframe后给iframe发送消息
    sendIframeMessage(url) {
      var iframe = document.getElementById("iframe");
      setTimeout(() => {
        if (iframe) {
          iframe.contentWindow.postMessage(
            {
              type: "videoUrl",
              videoUrl: url,
            },
            "*"
          );
        }
      }, 500);
      // 确保iframe存在
    },
    // 视频监控点击事件
    changeValue2(text) {
      this.isCarousel = false;
      this.value2 = text;
      // 查找label为"text"的对象
      let foundObject = this.option2.find((obj) => obj.label === text);

      if (foundObject) {
        // 根据data中checked的值来决定赋值给currentImg的属性
        this.currentImg = this.checked
          ? foundObject.flv_url
          : foundObject.channel_image;
      }
      this.sendIframeMessage(this.currentImg);
    },
    // 开始滚动
    startScroll() {
      this.tableScroll1(false);
      this.tableScroll2(false);
      this.tableScroll3(false);
      this.tableScroll4(false);
    },
    // 结束滚动
    stopScroll() {
      this.tableScroll1(true);
      this.tableScroll2(true);
      this.tableScroll3(true);
      this.tableScroll4(true);
    },
    // 滚动方法1
    tableScroll1(stop) {
      if (stop) {
        clearInterval(this.rolltimer);
        return;
      }

      const table1 = this.$refs.rolltable;
      const divData = table1?.bodyWrapper;

      if (this.rolltimer) {
        clearInterval(this.rolltimer);
      }

      const scrollFunction = () => {
        if (divData && divData.scrollTop !== undefined) {
          divData.scrollTop += 1;
          if (
            divData.clientHeight + divData.scrollTop + 1 >
            divData.scrollHeight
          ) {
            if (table1.tableData.length > 5) {
              divData.scrollTop = 1;
              clearInterval(this.rolltimer);
              this.tableScroll1(false);
            }
          }
        }
      };
      this.rolltimer = setInterval(scrollFunction, 40);
    },
    // 滚动方法2
    tableScroll2(stop) {
      if (stop) {
        clearInterval(this.rolltimer2);
        return;
      }
      const table2 = this.$refs.rolltable2;
      const divData2 = table2?.bodyWrapper;

      if (this.rolltimer2) {
        clearInterval(this.rolltimer2);
      }

      const scrollFunction = () => {
        if (divData2 && divData2.scrollTop !== undefined) {
          divData2.scrollTop += 1;
          if (
            divData2.clientHeight + divData2.scrollTop + 1 >
            divData2.scrollHeight
          ) {
            if (table2.tableData.length > 5) {
              divData2.scrollTop = 1;
              clearInterval(this.rolltimer2);
              this.tableScroll2(false);
            }
          }
        }
      };
      this.rolltimer2 = setInterval(scrollFunction, 40);
    },
    // 滚动方法3
    tableScroll3(stop) {
      if (stop) {
        clearInterval(this.rolltimer3);
        return;
      }

      const table3 = this.$refs.rolltable3;
      const divData3 = table3?.bodyWrapper;

      if (this.rolltimer3) {
        clearInterval(this.rolltimer3);
      }

      const scrollFunction = () => {
        if (divData3 && divData3.scrollTop !== undefined) {
          divData3.scrollTop += 1;
          if (
            divData3.clientHeight + divData3.scrollTop + 1 >
            divData3.scrollHeight
          ) {
            if (table3.tableData.length > 5) {
              divData3.scrollTop = 1;
              clearInterval(this.rolltimer3);
              this.tableScroll3(false);
            }
          }
        }
      };
      this.rolltimer3 = setInterval(scrollFunction, 40);
    },
    // 滚动方法4
    tableScroll4(stop) {
      if (stop) {
        clearInterval(this.rolltimer4);
        return;
      }
      const table4 = this.$refs.rolltable4;
      const divData4 = table4?.bodyWrapper;

      if (this.rolltimer4) {
        clearInterval(this.rolltimer4);
      }

      const scrollFunction = () => {
        if (divData4 && divData4.scrollTop !== undefined) {
          divData4.scrollTop += 1;
          if (
            divData4.clientHeight + divData4.scrollTop + 1 >
            divData4.scrollHeight
          ) {
            if (table4.tableData.length > 5) {
              divData4.scrollTop = 1;
              clearInterval(this.rolltimer4);
              this.tableScroll4(false);
            }
          }
        }
      };
      this.rolltimer4 = setInterval(scrollFunction, 40);
    },
    // 返回方法
    goback() {
      window.history.back();
    },
    // 去温湿度折线图页面
    goToTemperHumidity() {
      this.$router.push({ path: "/IoTMonitoring" });
      this.dialogVisible = false;
    },
    // 跳转到健康证查询页面
    goToHealthyList() {
      this.$router.push({ path: "/healthyList" });
      this.dialogVisible = false;
    },
    //餐厅大屏去监控页面
    toMonitor() {
      this.$router.push({ path: "/VideoMonitor" });
    },
    // 去营养大屏
    yingyang() {
      this.$router.replace({
        path: "/healthAi",
      });
    },
  },
  computed: {
    // 日期
    formattedDate: function () {
      var year = this.currentDate.getFullYear();
      var month = this.currentDate.getMonth() + 1;
      var day = this.currentDate.getDate();
      var weekdays = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      var weekday = weekdays[this.currentDate.getDay()];
      return year + "年" + month + "月" + day + "日 " + weekday;
    },
    groupedSuppList() {
      // 将一维数组转为二维数组，每组2个元素
      const chunkSize = 3;
      return this.suppList.reduce((acc, _, i) => {
        if (i % chunkSize === 0) {
          acc.push(this.suppList.slice(i, i + chunkSize));
        }
        return acc;
      }, []);
    },
  },
  mounted() {
    this.shop_owner = localStorage.getItem("shop_owner");
    this.shop_img = localStorage.getItem("shop_img");
    this.shop_name = localStorage.getItem("shop_name");
    this.shop_phone = localStorage.getItem("shop_phone");
    this.shop_users_amount = localStorage.getItem("shop_users_amount");
    this.shop_address = localStorage.getItem("shop_address");
    this.area = localStorage.getItem("area");
    this.users_amount = localStorage.getItem("users_amount");
    setTimeout(() => {
      this.tableScroll1(false);
      this.tableScroll2(false);
      this.tableScroll3(false);
      this.tableScroll4(false);
    }, 1000);
  },
};
