<template>
  <!-- 全屏容器 -->
  <!-- <dv-full-screen-container> -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <dv-border-box-11 title=" 仓库台账信息化展示大屏" v-if="changeDates">
        <div class="borderBox">
          <div
            style="
              height: 13%;
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              padding: 20px 195px 20px;
            "
          >
            <i
              class="el-icon-d-arrow-left"
              style="
                color: aliceblue;
                font-size: 20px;
                position: absolute;
                top: 140px;
                left: 35px;
              "
              @click="goback"
              >返回</i
            >
            <div style="width: 400px">
              <span
                style="
                  color: white;
                  font-size: 18px;
                  display: inline-block;
                  width: 150px;
                "
                >仓库台账类别：</span
              >
              <!-- <el-select
                v-model="selectValue"
                placeholder="请选择食安类别"
                @change="changeSelect"
              >
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
              <el-dropdown size="mini" split-button type="primary">
                <span class="el-dropdown-link">
                  {{ selectValue }}
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in options"
                    :key="item.value"
                    @click.native="changeValue1(item.label)"
                  >
                    <span> {{ item.label }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div>
              <el-date-picker
                value-format="yyyy-MM-dd"
                @change="changeDate"
                v-model="value"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 300px"
              >
              </el-date-picker>
            </div>
            <div class="block" style="display: flex">
              <el-input
                @change="nameChange"
                placeholder="请输入关键词"
                v-model="requestData.search_key"
                clearable
              >
              </el-input>
              <el-button
                style="margin-left: 20px; width: 220px"
                type="primary"
                icon="el-icon-search"
                @click="search"
                >搜索</el-button
              >
            </div>
          </div>
          <div style="width: 80%; margin: 0 auto; height: 65%">
            <tableCom
              v-if="changeDates"
              :tableTitle="tableTitle"
              :tableDatas="tableSource"
              class="left-three-item"
            >
              <template #pic_image="{ scope }">
                <div>
                  <el-popover placement="right" width="400" trigger="hover">
                    <el-row>
                      <el-col>
                        <el-card>
                          <img
                            style="width: 100%; height: 350px"
                            :src="scope.row.pic_image"
                            class="image"
                          />
                        </el-card>
                      </el-col>
                    </el-row>
                    <el-image
                      style="width: 60px; height: 50px"
                      :src="scope.row.pic_image"
                      slot="reference"
                    >
                    </el-image>
                  </el-popover>
                </div>
              </template>
              <template #company_image="{ scope }">
                <div>
                  <el-popover placement="right" width="400" trigger="hover">
                    <el-row>
                      <el-col>
                        <el-card>
                          <img
                            width="100%"
                            :src="scope.row.company_image"
                            class="image"
                          />
                        </el-card>
                      </el-col>
                    </el-row>
                    <el-image
                      style="width: 50px; height: 40px"
                      :src="scope.row.company_image"
                      slot="reference"
                    >
                    </el-image>
                  </el-popover>
                </div>
              </template>
              <template #waste_image="{ scope }">
                <div>
                  <el-popover placement="right" width="400" trigger="hover">
                    <el-row>
                      <el-col>
                        <el-card>
                          <img
                            width="100%"
                            :src="scope.row.waste_image"
                            class="image"
                          />
                        </el-card>
                      </el-col>
                    </el-row>
                    <el-image
                      style="width: 50px; height: 40px"
                      :src="scope.row.waste_image"
                      slot="reference"
                    >
                    </el-image>
                  </el-popover>
                </div>
              </template>
              <template #business_image="{ scope }">
                <div>
                  <el-popover placement="right" width="400" trigger="hover">
                    <el-row>
                      <el-col>
                        <el-card>
                          <img
                            width="100%"
                            :src="scope.row.business_image"
                            class="image"
                          />
                        </el-card>
                      </el-col>
                    </el-row>
                    <el-image
                      style="width: 50px; height: 40px"
                      :src="scope.row.business_image"
                      slot="reference"
                    >
                    </el-image>
                  </el-popover>
                </div>
              </template>
              <template #food_image="{ scope }">
                <div>
                  <el-popover placement="right" width="400" trigger="hover">
                    <el-row>
                      <el-col>
                        <el-card>
                          <img
                            width="100%"
                            :src="scope.row.food_image"
                            class="image"
                          />
                        </el-card>
                      </el-col>
                    </el-row>
                    <el-image
                      style="width: 50px; height: 40px"
                      :src="scope.row.food_image"
                      slot="reference"
                    >
                    </el-image>
                  </el-popover>
                </div>
              </template>
              <template #produce_image="{ scope }">
                <div>
                  <el-popover placement="right" width="400" trigger="hover">
                    <el-row>
                      <el-col>
                        <el-card>
                          <img
                            width="100%"
                            :src="scope.row.produce_image"
                            class="image"
                          />
                        </el-card>
                      </el-col>
                    </el-row>
                    <el-image
                      style="width: 50px; height: 40px"
                      :src="scope.row.produce_image"
                      slot="reference"
                    >
                    </el-image>
                  </el-popover>
                </div>
              </template>
              <template #img2="{ scope }">
                <div>
                  <el-popover placement="right" width="400" trigger="hover">
                    <el-row>
                      <el-col>
                        <el-card>
                          <img
                            width="100%"
                            :src="scope.row.img2"
                            class="image"
                          />
                        </el-card>
                      </el-col>
                    </el-row>
                    <el-image
                      style="width: 50px; height: 40px"
                      :src="scope.row.img2"
                      slot="reference"
                    >
                    </el-image>
                  </el-popover>
                </div>
              </template>
              <template #img4="{ scope }">
                <div>
                  <el-popover placement="right" width="400" trigger="hover">
                    <el-row>
                      <el-col>
                        <el-card>
                          <img
                            width="100%"
                            :src="scope.row.img4"
                            class="image"
                          />
                        </el-card>
                      </el-col>
                    </el-row>
                    <el-image
                      style="width: 50px; height: 40px"
                      :src="scope.row.img4"
                      slot="reference"
                    >
                    </el-image>
                  </el-popover>
                </div>
              </template>
              <template #img1="{ scope }">
                <div>
                  <el-popover placement="right" width="400" trigger="hover">
                    <el-row>
                      <el-col>
                        <el-card>
                          <img
                            width="100%"
                            :src="scope.row.img1"
                            class="image"
                          />
                        </el-card>
                      </el-col>
                    </el-row>
                    <el-image
                      style="width: 50px; height: 40px"
                      :src="scope.row.img1"
                      slot="reference"
                    >
                    </el-image>
                  </el-popover>
                </div>
              </template>
              <template #img3="{ scope }">
                <div>
                  <el-popover placement="right" width="400" trigger="hover">
                    <el-row>
                      <el-col>
                        <el-card>
                          <img
                            width="100%"
                            :src="scope.row.img3"
                            class="image"
                          />
                        </el-card>
                      </el-col>
                    </el-row>
                    <el-image
                      style="width: 50px; height: 40px"
                      :src="scope.row.img3"
                      slot="reference"
                    >
                    </el-image>
                  </el-popover>
                </div>
              </template>
            </tableCom>
          </div>
          <el-pagination
            :page-size="10"
            :current-page.sync="currentPage"
            @current-change="handleCurrentChange"
            style="position: absolute; right: 185px; bottom: 130px"
            background
            layout="prev, pager, next"
            :total="pageTotal"
          ></el-pagination>
        </div>
      </dv-border-box-11>
    </div>
  </v-scale-screen>
  <!-- </dv-full-screen-container> -->
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import {
  getHealthTable,
  getTastingTable,
  getMenuTable,
  getRetentionTable,
  getWasteTable,
  getCzjxdTable,
  getHealthcheckTable,
  getCjxdTable,
  getArea8cleanTable,
  getPsgcleanTable,
  getCzjselfcheckTable,
  getColdstorecheckTable,
  getPestcontrolTable,
  getCloseshopTable,
  getSupplierTable,
  getTest
} from "../api/api";

export default {
  data() {
    return {
      currentPage: 1,
      pageTotal: 0,
      changeDates: true, //控制表格刷新
      requestData: {
        start_date: "",
        end_date: "",
        page: 1,
        search_key: "",
      },
      baseUrl: "http://aishop.ufoodlife.cn",
      value: "",
      tableSource: [],
      tableTitle: [],
      options: [],
      selectValue: "",
      tableMap: {
        "健康证": this.getHealthTable,
        "每周菜谱": this.getMenuTable,
        "品尝记录": this.getTastingTable,
        "每日留样": this.getRetentionTable,
        "废弃物处理": this.getWasteTable,
        "晨检记录": this.getHealthcheckTable,
        "餐具消毒": this.getCjxdTable,
        "操作间消毒": this.getCzjxdTable,
        "区域八净": this.getArea8cleanTable,
        "排水沟清洁": this.getPsgcleanTable,
        "操作间自检": this.getCzjselfcheckTable,
        "冷库巡检": this.getColdstorecheckTable,
        "虫害防治": this.getPestcontrolTable,
        "闭店安检": this.getCloseshopTable,
        "供应商资质管理": this.getSupplierTable,
        "蔬菜台账记录":this.getTest,
        "肉类台账记录":this.getTest,
        "蔬菜台账记录":this.getTest,
      },
    };
  },
  methods: {
    changeValue1(e) {
      this.selectValue = e;
      this.changeDates = false;
      if (e == "健康证") {
        this.getHealthTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "健康证",
            width: "200",
            value: "pic_image",
          },
          {
            name: "姓名",
            width: "",
            value: "name",
          },
          {
            name: "健康证编号",
            width: "",
            value: "code",
          },
          {
            name: "健康证到期日期",
            width: "",
            value: "end_date",
          },
        ];
      } else if (e == "每周菜谱") {
        this.getMenuTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "200",
            value: "date",
          },
          {
            name: "餐次",
            width: "80",
            value: "meal_name",
          },
          {
            name: "套餐内容",
            width: "",
            value: "food_content",
          },
        ];
      } else if (e == "品尝记录") {
        this.getTastingTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "200",
            value: "date",
          },
          {
            name: "餐次",
            width: "",
            value: "meal_name",
          },
          {
            name: "档口",
            width: "",
            value: "room_name",
          },
          {
            name: "菜品名称",
            width: "",
            value: "food_name",
          },
          {
            name: "中心温度(摄氏度)",
            width: "150",
            value: "temp",
          },
          {
            name: "品尝数量",
            width: "",
            value: "taste_number",
          },
          {
            name: "品尝结果",
            width: "",
            value: "taste_result",
          },
          {
            name: "品尝人",
            width: "",
            value: "taste_user",
          },
        ];
      } else if (e == "每日留样") {
        this.getRetentionTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "留样时间",
            width: "200",
            value: "action_date",
          },
          {
            name: "餐次",
            width: "",
            value: "meal_name",
          },
          {
            name: "档口",
            width: "",
            value: "room_name",
          },
          {
            name: "菜品名称",
            width: "",
            value: "food_name",
          },
          {
            name: "保存条件(摄氏度)",
            width: "150",
            value: "temp",
          },
          {
            name: "重量",
            width: "",
            value: "weight",
          },
          {
            name: "留样人",
            width: "",
            value: "retention_user",
          },
          {
            name: "监督人",
            width: "",
            value: "verify_name",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (e == "废弃物处理") {
        this.getWasteTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "action_date",
          },
          {
            name: "种类",
            width: "",
            value: "class",
          },
          {
            name: "数量",
            width: "",
            value: "number",
          },
          {
            name: "联系方式",
            width: "",
            value: "phone",
          },
          {
            name: "经手人",
            width: "",
            value: "handler_user_name",
          },
          {
            name: "回收人",
            width: "",
            value: "user_name",
          },
          {
            name: "图片",
            width: "",
            value: "waste_image",
          },
          {
            name: "资质图片",
            width: "",
            value: "company_image",
          },
          {
            name: "回收单位",
            width: "250",
            value: "company",
          },
        ];
      } else if (e == "晨检记录") {
        this.getHealthcheckTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "200",
            value: "date",
          },
          {
            name: "姓名",
            width: "",
            value: "user_name",
          },
          {
            name: "性别",
            width: "",
            value: "sex",
          },
          {
            name: "健康状况",
            width: "",
            value: "health_type",
          },
          {
            name: "卫生状况",
            width: "",
            value: "hygiene_type",
          },
          {
            name: "体温",
            width: "",
            value: "temp",
          },
        ];
      } else if (e == "餐具消毒") {
        this.getCjxdTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "档口",
            width: "",
            value: "room_name",
          },
          {
            name: "餐别名称",
            width: "",
            value: "meal_name",
          },
          {
            name: "消毒餐具",
            width: "",
            value: "disinfection_name",
          },
          {
            name: "数量",
            width: "",
            value: "number",
          },
          {
            name: "消毒开始时间",
            width: "",
            value: "start_time",
          },
          {
            name: "消毒结束时间",
            width: "",
            value: "end_time",
          },
          {
            name: "操作人",
            width: "",
            value: "operator_user",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (e == "操作间消毒") {
        this.getCzjxdTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "处理时间",
            width: "",
            value: "date",
          },
          {
            name: "操作间名称",
            width: "",
            value: "room_name",
          },
          {
            name: "消毒名称",
            width: "",
            value: "disinfection_name",
          },
          {
            name: "消毒方案",
            width: "",
            value: "method",
          },
          {
            name: "消毒开始时间",
            width: "",
            value: "start_time",
          },
          {
            name: "消毒结束时间",
            width: "",
            value: "end_time",
          },
          {
            name: "负责人",
            width: "",
            value: "operator_user",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "消毒图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (e == "区域八净") {
        this.getArea8cleanTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "清洁区域",
            width: "350",
            value: "clean_area",
          },
          {
            name: "操作人",
            width: "",
            value: "operator_user",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (e == "排水沟清洁") {
        this.getPsgcleanTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "操作间",
            width: "",
            value: "clean_area",
          },
          {
            name: "清洁方式",
            width: "",
            value: "clean_area",
          },
          {
            name: "操作人",
            width: "",
            value: "operator_user",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (e == "操作间自检") {
        this.getCzjselfcheckTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "自检区",
            width: "",
            value: "room_name",
          },
          {
            name: "自检内容",
            width: "",
            value: "check_item_json",
          },
          {
            name: "自检情况",
            width: "",
            value: "check_result",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (e == "冷库巡检") {
        this.getColdstorecheckTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "档口",
            width: "",
            value: "room_name",
          },
          {
            name: "冰库冰柜编码",
            width: "",
            value: "code",
          },
          {
            name: "巡察温度(℃)",
            width: "",
            value: "tmp",
          },
          {
            name: "巡察时间",
            width: "",
            value: "check_time",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "审核人",
            width: "",
            value: "verify_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (e == "虫害防治") {
        this.getPestcontrolTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "档口",
            width: "",
            value: "room_name",
          },
          {
            name: "操作间",
            width: "",
            value: "opertor_name",
          },
          {
            name: "防治类型",
            width: "",
            value: "method",
          },
          {
            name: "提交时间",
            width: "",
            value: "check_time",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
          {
            name: "审核人",
            width: "",
            value: "verify_user",
          },
          {
            name: "图片",
            width: "",
            value: "pic_image",
          },
        ];
      } else if (e == "闭店安检") {
        this.getCloseshopTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "date",
          },
          {
            name: "门窗紧闭",
            width: "",
            value: "doorandwindow",
          },
          {
            name: "水闸、燃气、电闸关闭",
            width: "",
            value: "water_ele_gas",
          },
          {
            name: "是否休息",
            width: "",
            value: "is_close",
          },
          {
            name: "检查人",
            width: "",
            value: "check_user",
          },
        ];
      } else if (e == "供应商资质管理") {
        this.getSupplierTable();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "供应商名称",
            width: "300",
            value: "name",
          },
          // {
          //   name: "组织代码",
          //   width: "",
          //   value: "creditid",
          // },
          {
            name: "地址",
            width: "300",
            value: "address",
          },
          {
            label: "营业执照",
            width: "",
            childrens: [
              {
                name: "营业执照",
                width: "",
                value: "business_image",
              },
              {
                name: "食品安全许可证",
                width: "",
                value: "food_image",
              },
              // {
              //   name: "生产经营许可证",
              //   width: "",
              //   value: "produce_image",
              // },
            ],
          },
          {
            name: "营业执照到期时间",
            width: "",
            value: "business_due_date",
          },
          {
            name: "食品安全许可证到期时间",
            width: "",
            value: "food_due_date",
          },
          // {
          //   name: "生产经营许可证到期时间",
          //   width: "",
          //   value: "produce_due_date",
          // },
        ];
      }else if(e == "蔬菜台账记录"){
        this.getTest();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "text1",
          },
          {
            name: "产品名称",
            width: "",
            value: "text2",
          },
          {
            name: "规格",
            width: "",
            value: "text3",
          },
          {
            name: "数量",
            width: "",
            value: "text4",
          },
          {
            name: "生产批号或日期",
            width: "",
            value: "text5",
          },
          {
            name: "供货商",
            width: "",
            value: "text6",
          },
          // {
          //   name: "供货商电话",
          //   width: "",
          //   value: "text7",
          // },
          // {
          //   label: "营业执照",
          //   width: "",
          //   value: "img1"
          // },
          {
            name: "营业执照",
            width: "",
            value: "img2",
          },
          {
            name: "产品检验报告",
            width: "",
            value: "img3",
          },
          {
            name: "供货凭证",
            width: "",
            value: "img4",
          },{
            name: "外观检查",
            width: "",
            value: "text9",
          },
          {
            name: "记录人",
            width: "",
            value: "text8",
          }
        ];
      }else if(e == "肉类台账记录"){
        this.getTest();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "name",
          },
          {
            name: "产品名称",
            width: "",
            value: "creditid",
          },
          {
            name: "规格",
            width: "",
            value: "address",
          },
          {
            name: "数量",
            width: "",
            value: "name",
          },
          {
            name: "生产批号或日期",
            width: "",
            value: "creditid",
          },
          {
            name: "供货商",
            width: "",
            value: "address",
          },
          // {
          //   name: "供货商电话",
          //   width: "",
          //   value: "address",
          // },
          {
            label: "营业执照",
            width: "",
            value: "img1"
          },
          {
            name: "购货凭证",
            width: "",
            value: "business_due_date",
          },
          {
            name: "产品检验报告",
            width: "",
            value: "food_due_date",
          },
          {
            name: "其它合格证明",
            width: "",
            value: "produce_due_date",
          },
          {
            name: "外观检查",
            width: "",
            value: "food_due_date",
          },
          {
            name: "记录人",
            width: "",
            value: "produce_due_date",
          }
        ];
      }else if(e == "粮油台账记录"){
        this.getTest();
        this.requestData.page = 1;
        this.requestData.search_key = "";
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "name",
          },
          {
            name: "产品名称",
            width: "",
            value: "creditid",
          },
          {
            name: "规格",
            width: "",
            value: "address",
          },
          {
            name: "数量",
            width: "",
            value: "name",
          },
          {
            name: "生产批号或日期",
            width: "",
            value: "creditid",
          },
          {
            name: "供货商",
            width: "",
            value: "address",
          },
          // {
          //   name: "供货商电话",
          //   width: "",
          //   value: "address",
          // },
          {
            label: "营业执照",
            width: "",
            childrens: [
              {
                name: "营业执照",
                width: "",
                value: "business_image",
              },
              {
                name: "食品安全许可证",
                width: "",
                value: "food_image",
              },
              // {
              //   name: "生产经营许可证",
              //   width: "",
              //   value: "produce_image",
              // },
            ],
          },
          {
            name: "购货凭证",
            width: "",
            value: "business_due_date",
          },
          {
            name: "产品检验报告",
            width: "",
            value: "food_due_date",
          },
          {
            name: "其它合格证明",
            width: "",
            value: "produce_due_date",
          },{
            name: "外观检查",
            width: "",
            value: "food_due_date",
          },
          {
            name: "记录人",
            width: "",
            value: "produce_due_date",
          }
        ];
      }else if(e == "食品添加剂领用记录"){
        this.tableTitle = [
          {
            name: "日期",
            width: "",
            value: "name",
          },
          {
            name: "名称",
            width: "",
            value: "creditid",
          },
          {
            name: "生产日期或批号",
            width: "",
            value: "address",
          },
          {
            name: "领用量",
            width: "",
            value: "name",
          },
          {
            name: "发放人",
            width: "",
            value: "creditid",
          },
          {
            name: "部门",
            width: "",
            value: "address",
          },
          {
            name: "领用人",
            width: "",
            value: "address",
          },
        ];
      }
      setTimeout(() => {
        this.changeDates = true;
      }, 200);

      this.currentPage = 1;
    },
    handleCurrentChange(val) {
      this.requestData.page = val;
      const selectedFunction = this.tableMap[this.selectValue];
      if (selectedFunction) {
        selectedFunction();
        this.requestData.page = 1;
      }
    },
    search() {
      const selectedFunction = this.tableMap[this.selectValue];
      if (selectedFunction) {
        selectedFunction();
        this.requestData.page = 1;
      }
    },
    // 健康证搜索框change事件
    nameChange(e) {
      this.requestData.search_key = e;
    },
    // 时间选择器change事件
    changeDate(e) {
      this.requestData.start_date = e[0];
      this.requestData.end_date = e[1];
      this.changeValue1(this.selectValue);
    },
    goback() {
      this.$router.push("/larges");
    },
    // 获取健康证数据
    async getHealthTable() {
      const res = await getHealthTable(this.requestData);
      this.tableSource = res.data.data[0].data.map((item) => {
        return {
          ...item,
          pic_image: this.baseUrl + item.pic_image,
        };
      });
      this.pageTotal = res.data.data[0].total;
    },
    // 获取品尝记录数据
    async getTastingTable() {
      const res = await getTastingTable(this.requestData);
      this.tableSource = res.data.data[0].data;
      this.pageTotal = res.data.data[0].total;
    },
    // 查看菜谱
    async getMenuTable() {
      const res = await getMenuTable(this.requestData);
      this.tableSource = res.data.data[0].data;
      this.pageTotal = res.data.data[0].total;
    },
    // 查看留样记录
    async getRetentionTable() {
      const res = await getRetentionTable(this.requestData);
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: this.baseUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
      this.pageTotal = res.data.data[0].total;
    },
    // 废弃物处置记录
    async getWasteTable() {
      const res = await getWasteTable(this.requestData);
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.waste_image.includes("http")) {
          return {
            ...item,
            waste_image: this.baseUrl + item.waste_image,
            company_image: this.baseUrl + item.company_image,
          };
        } else {
          return item;
        }
      });
      this.pageTotal = res.data.data[0].total;
    },
    // 获取晨检记录
    async getHealthcheckTable() {
      const res = await getHealthcheckTable(this.requestData);
      this.tableSource = res.data.data[0].data;
      this.pageTotal = res.data.data[0].total;
    },
    // 获取餐具消毒
    async getCjxdTable() {
      const res = await getCjxdTable(this.requestData);
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: this.baseUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
      this.pageTotal = res.data.data[0].total;
    },
    // 获取操作间消毒
    async getCzjxdTable() {
      const res = await getCzjxdTable(this.requestData);
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: this.baseUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
      this.pageTotal = res.data.data[0].total;
    },
    // 获取区域八净
    async getArea8cleanTable() {
      const res = await getArea8cleanTable(this.requestData);
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: this.baseUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
      this.pageTotal = res.data.data[0].total;
    },
    // 获取排水沟清洁
    async getPsgcleanTable() {
      const res = await getPsgcleanTable(this.requestData);
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: this.baseUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
      this.pageTotal = res.data.data[0].total;
    },
    // 获取操作间自检
    async getCzjselfcheckTable() {
      const res = await getCzjselfcheckTable(this.requestData);
      this.tableSource = res.data.data[0].data.map((item, index) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: this.baseUrl + item.pic_image,
            check_item_json: Object.keys(JSON.parse(item.check_item_json))[0],
          };
        } else {
          return item;
        }
      });
      this.pageTotal = res.data.data[0].total;
    },
    // 获取冷库巡检
    async getColdstorecheckTable() {
      const res = await getColdstorecheckTable(this.requestData);
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: this.baseUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
      this.pageTotal = res.data.data[0].total;
    },
    // 获取冷库巡检
    async getPestcontrolTable() {
      const res = await getPestcontrolTable(this.requestData);
      this.tableSource = res.data.data[0].data.map((item) => {
        if (!item.pic_image.includes("http")) {
          return {
            ...item,
            pic_image: this.baseUrl + item.pic_image,
          };
        } else {
          return item;
        }
      });
      this.pageTotal = res.data.data[0].total;
    },
    // 闭店安检
    async getCloseshopTable() {
      const res = await getCloseshopTable(this.requestData);
      this.tableSource = res.data.data[0].data;
      // console.log(res.data,'res.data闭店安检');
      this.pageTotal = res.data.data[0].total;
    },
    // 获取供应商档案
    async getSupplierTable() {
      const res = await getSupplierTable(this.requestData);
      this.tableSource = res.data.data.data.map((item) => {
        if (!item.business_image.includes("http")) {
          return {
            ...item,
            business_image: this.baseUrl + item.business_image,
            food_image: this.baseUrl + item.food_image,
            produce_image: this.baseUrl + item.produce_image,
          };
        } else {
          return item;
        }
      });
      this.pageTotal = res.data.data.total;
    },
    // 获取供应商档案
    async getTest() {
      const res = await getTest(this.requestData);
      // this.tableSource = res.data.data.data.map((item) => {
      //   if (!item.img1.includes("http")) {
      //     return {
      //       ...item,
      //       img1: this.baseUrl + item.img1,
      //       img2: this.baseUrl + item.img2,
      //       img3: this.baseUrl + item.img3,
      //     };
      //   } else {
      //     return item;
      //   }
      // });
      this.tableSource = res.data.data.data
      this.pageTotal = res.data.data.total;
    },
  },
  components: { ChartBox, Chart },
  created() {    
    this.options = this.$route.query.cate.map((item) => {
      return {
        value: item.name,
        label: item.label,
      };
    });
    this.selectValue = this.options[0].label
    this.changeValue1(this.selectValue)
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
// 表格样式
// 查看大图样式
.imgwrap {
  height: 100%;
  width: 100%;
  position: relative;

  .close {
    position: absolute;
    width: 3.125rem;
    height: 3.125rem;
    right: 0.625rem;
    top: 0.625rem;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.container {
  width: 1920px;
  height: 1080px;
  background: url("../assets/image/bg.577ccc4c.jpg");
  .borderBox {
    height: 100%;
    width: 100%;
  }
}
::v-deep .container .el-table .cell {
  height: 55px; //表格行高
  line-height: 55px;
}
</style>
<style lang="scss" scoped>
//这里没有scope
.container // 滚动条样式
.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background-color: #99a9bf !important;
}
::-webkit-scrollbar-track-piece {
  // background: rgb(1, 22, 55);
}

::-webkit-scrollbar {
  width: 0.375rem;
}

::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 1.25rem;
}

.container .el-table thead {
  border: 2px solid rgb(25, 169, 184) !important;
  color: white;
}
.container .el-table .cell {
  color: white;
  font-size: 15px;
}
/* // 更改表头背景颜色 */
.container .el-table thead tr > th {
  // background-color: rgb(1, 52, 92);
  height: 15px;
  line-height: 15px;
}
.el-table thead.is-group th.el-table__cell {
  background: rgb(1, 52, 92) !important;
}
.el-table--border th.el-table__cell {
  border: none !important;
  text-align: center;
}
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  // background-color: rgb(1, 52, 92) !important;
  border: none;
}
/* // 更改表格每行背景颜色 */
.container .el-table tr {
  // background-color: transparent;
}
.container .el-table tbody tr > td {
  // background-color: rgb(1, 20, 49);
}
.container .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  height: 40px;
  border: none !important;
}
/* // 设置鼠标经过时背景颜色 */
.container .el-table tbody tr:hover > td {
  background-color: #fffff0 !important;
}
.container .el-table .el-table__cell {
  // border: 1px solid #999;
  padding: 5px 0;
}

.container .el-table__body-wrapper::-webkit-scrollbar {
  width: 0.375rem !important;
}
.container .el-range-editor.el-input__inner {
  background-color: rgb(1, 52, 92);
  color: white;
}
.container .el-range-editor span {
  color: white;
  font-weight: bolder;
}
.container .el-date-editor .el-range-input {
  background-color: rgb(1, 52, 92);
  color: white;
}
.container .el-input--suffix .el-input__inner {
  background-color: rgb(1, 52, 92);
  color: white;
}
</style>
