<template>
  <div class="goToTemperHumidity">
    <ChartBox
      :title="shop_name.includes('鑫晟') ? ' 物联网设备监控' : '温湿度监控'"
      :tip="true"
    >
      <div class="double-column-layout">
        <!-- 温湿度列 -->
        <transition name="el-fade-in">
          <el-table
            :data="temperatureData"
            style="width: 100%; height: 230px"
            v-if="activeTable === 'temperature'"
          >
            <el-table-column
              label="温湿度实时监控"
              align="center"
              header-align="center"
            >
              <el-table-column
                prop="name"
                label="地点"
                align="center"
              ></el-table-column>

              <!-- 温度列 -->
              <el-table-column label="温度" align="center">
                <template #header>
                  <div class="header-with-icon" style="display: flex;align-items: center;justify-content: center;">                    
                    <span>温度</span>
                    <img
                      src="../assets/image/wendu.png"
                      class="header-icon"
                      style="width: 15px; height: 17px;margin-left: 2px;"
                    />
                  </div>
                </template>
                <template #default="{ row }">
                  <div v-if="row.temp">
                    <span 
                    style="font-weight: bold;" :style="{ color: getStatusColor(row.temp_status) }"
                      >{{ row.temp }}℃</span
                    >
                  </div>
                  <span v-else>-</span>
                </template>
              </el-table-column>

              <!-- 湿度列 -->
              <el-table-column label="湿度" align="center">
                <template #header>
                  <div class="header-with-icon" style="display: flex;align-items: center;justify-content: center;">
                    <span>湿度</span>
                    <img
                      src="../assets/image/shidu.png"
                      class="header-icon"
                      style="width: 15px; height: 15px;margin-left: 2px;"
                    />
                  </div>
                </template>
                <template #default="{ row }">
                  <div v-if="row.wet">
                    <span
                      v-if="row.wet > 0"
                      style="font-weight: bold;"
                      :style="{ color: getStatusColor(row.temp_status) }"
                      >{{ row.wet }}%</span
                    >
                    <span v-else>-</span>
                  </div>
                  <span v-else>-</span>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </transition>

        <!-- 燃气列 -->
        <transition name="el-fade-in">
          <el-table
            :data="gasData"
            style="width: 100%; height: 230px"
            v-if="activeTable === 'gas'"
          >
            <el-table-column
              label="燃气实时监控"
              align="center"
              header-align="center"
            >
              <el-table-column
                prop="name"
                label="地点"
                width="120"
                align="center"
              ></el-table-column>
              <el-table-column label="数值" align="center">
                <template #header>
                  <div class="header-with-icon" style="display: flex;align-items: center;justify-content: center;">
                    <span>数值</span>
                    <img
                      src="../assets/image/jiawan.png"
                      class="header-icon"
                      style="width: 15px; height: 15px;margin-left: 2px;"
                    />
                  </div>
                </template>
                <template #default="{ row }">
                  <div>
                    <!-- 显示数值 -->
                    <span
                    style="font-weight: bold;" :style="{ color: getStatusColor(row.status) }"
                      >{{ row.val }} %LEL</span
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </transition>
      </div>
    </ChartBox>
  </div>
</template>

<script>
import ChartBox from "../components/chartBox.vue";
import { getIotInfoV2 } from "../api/api";
import { baseImgUrl } from "../utils/request";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      temperatureData: [], // 温湿度数据
      gasData: [], // 燃气数据
      activeTable: "temperature", // 当前显示表格
      timer: null,
    };
  },
  components: {
    ChartBox,
  },
  mixins: [myMixin],
  mounted() {
    this.getIotInfoV();
    this.startCarousel();
  },
  beforeDestroy() {
    this.stopCarousel();
  },
  methods: {
    // 获取地点
    async getIotInfoV() {
      const res = await getIotInfoV2();
      var newArr = [];
      if (res.data.data.temp && res.data.data.temp.length > 0) {
        newArr = [...res.data.data.temp];
      }
      if (res.data.data.jiawan && res.data.data?.jiawan.length > 0) {
        newArr = [...newArr, ...res.data.data?.jiawan];
      }
      if (res.data.data.smog && res.data.data?.smog.length > 0) {
        newArr = [...newArr, ...res.data.data.smog];
      }
      this.locationTempList = newArr;
      this.processData(this.locationTempList);

      console.log(this.locationTempList, "this.locationTempList");
    },
    // 数据处理方法
    processData(list) {
      this.temperatureData = list
        .filter((item) => "temp" in item || "wet" in item)
        .slice(0, 5); // 最多显示4条

      this.gasData = list
        .filter((item) => "status" in item && "val" in item)
        .slice(0, 5); // 最多显示4条
    },
    startCarousel() {
      this.timer = setInterval(() => {
        this.activeTable =
          this.activeTable === "temperature" ? "gas" : "temperature";
      }, 10000); // 10秒切换
    },
    stopCarousel() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    // 状态颜色映射
    getStatusColor(status) {
      const colorMap = {
        正常: "#008000",
        警告: "#ffa500",
        报警: "#ff0000",
      };
      return colorMap[status] || "#606266"; // 默认返回Element默认颜色
    },
  },
};
</script>

<style lang="scss" scoped>
.double-column-layout {
  display: flex;
  justify-content: space-between;
}

::v-deep .el-table__header-wrapper {
  background-color: #01345c;
}
::v-deep .el-table thead.is-group th.el-table__cell {
  border: none !important;
  background-color: #01173d !important;
  color: white !important;
  font-size: 16px;
}

.el-table th {
  font-weight: 600;
  color: #303133;
}

.double-column-layout ::v-deep .el-table th.el-table__cell.is-leaf {
  padding: 6px 0 !important;
  background-color: #021331 !important;
  color: white !important;
  border: none !important;
  font-size: 14px;
}
.double-column-layout ::v-deep .el-table td.el-table__cell {
  padding: 4px 0 !important;
  background-color: #011431 !important;
  color: #999 !important;
  border: none !important;
  font-size: 14px;
}
.double-column-layout ::v-deep .el-table {
  transform: scale(1) !important;
  width: 100% !important;
  margin-left: 0px !important;
  border: 1px solid #011431 !important;
  background-color: #011431;
}

::v-deep .el-table--border::after,
.el-table--group::after {
  width: 0px !important;
}
::v-deep .el-table::before {
  height: 0px !important;
}
</style>
