<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <!-- 标题栏部分 -->
      <div class="header">
        <div class="shopName">优食安数字食堂云平台</div>
        <div class="other">
          <div class="msg">
            <el-badge :value="2" class="item">
              <img src="../assets/image/msg.png" style="width: 25px" />
            </el-badge>
          </div>
          <div style="display: flex; align-items: center">
            <div class="head">
              <img src="../assets/image/my.png" style="width: 25px" />
            </div>
            <el-dropdown trigger="click">
              <span
                class="el-dropdown-link"
                style="font-size: 18px; color: white"
              >
              安医大二附院食堂（优芙得）<i
                  class="el-icon-arrow-down el-icon--right"
                ></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  style="
                    display: flex;
                    align-items: center;
                    font-weight: bolder;
                  "
                  ><img
                    src="../assets/image/tuichu.png"
                    style="width: 20px; margin-right: 10px"
                  />安全退出</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="tabLIst">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
        <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">物联网监控</el-menu-item>
          <el-menu-item index="3">明厨亮灶</el-menu-item>
          <el-menu-item index="4">店长助手每日任务清单</el-menu-item>
          <el-menu-item index="5">营养分析</el-menu-item>
          <el-menu-item index="6">每周菜单</el-menu-item>
          <el-menu-item index="7">人员证照</el-menu-item>
          <el-menu-item index="8">供应商管理</el-menu-item>
          <el-menu-item index="9">产品溯源</el-menu-item>
          <el-menu-item index="10">智能留样</el-menu-item>
          <el-menu-item index="11">农残检测</el-menu-item>
          <el-menu-item index="12">消毒记录</el-menu-item>
          <el-menu-item index="13">整改记录</el-menu-item>
        </el-menu>
        <div
          style="position: absolute; right: 30px; top: 90px; font-size: 18px"
        >
          <i class="el-icon-more"></i>
          <span style="margin-left: 10px">更多</span>
        </div>
      </div>
      <div class="box-container">
        <aside>
          <!-- 左一 -->
          <div class="box-left-one">
            <component
              v-if="module1 != ''"
              :is="moduleComponents[1]"
              style="height: 100%"
            ></component>
            <roomInfoModule style="height: 100%" v-else></roomInfoModule>
          </div>
          <!-- 左二 -->
          <div class="box-left-two">
            <component
              v-if="module2 != ''"
              :is="moduleComponents[1]"
              style="height: 100%"
            ></component>
            <div style="height: 100%" v-else @click="goToTemperHumidity">
              <wettmpInfoModule style="height: 100%"></wettmpInfoModule>
            </div>
          </div>
          <!-- 左三 -->
          <div class="box-left-three">
            <component
              v-if="module3 != ''"
              :is="moduleComponents[2]"
              style="height: 100%"
            ></component>

            <div style="height: 100%" v-else @click="goToHealthyList">
              <healthInfoModule style="height: 100%"></healthInfoModule>
            </div>
          </div>
        </aside>

        <section>
          <!-- 证件表格 -->
          <div class="center-buttom">
            <TabPane :tableTeight="tableTeight" :tabList="tabList"></TabPane>
          </div>
          <div>
            <ChartBox
              title="店长助手-每日任务自检清单"
              v-if="module7 == 'storeManage'"
            >
              <el-carousel
                :interval="5000"
                height="230px"
                indicator-position="none"
              >
                <el-carousel-item
                  v-for="(item, index) in newTaskList"
                  :key="index"
                  style="display: flex; justify-content: center"
                >
                  <div class="elItem">
                    <div class="el">
                      <div class="guize">
                        <div class="oneTitle">
                          <i class="el-icon-s-claim"></i>
                          {{ item.group_name }}
                          <span style="font-size: 16px"
                            >（{{ item.time }}）</span
                          >
                        </div>
                        <div class="twoTitle">
                          <div class="tow">
                            <i
                              style="margin: 0 10px 0 5px; color: black"
                              class="el-icon-paperclip"
                            ></i>
                            <div class="describle">
                              {{ item.describle }}
                            </div>
                          </div>
                          <ul
                            style="
                              margin-top: 10px;
                              margin-left: 2px;
                              color: black;
                              font-size: 16px;
                            "
                            v-for="(k, inde) in item.arr_score_content"
                          >
                            <li style="list-style: none">
                              <div
                                style="
                                  display: flex;
                                  justify-content: space-between;
                                  margin: 12px 0;
                                "
                              >
                                <div>{{ k.value }}</div>
                                <el-checkbox v-model="k.checked"
                                  >符合</el-checkbox
                                >
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <el-image
                        style="
                          width: 20%;
                          height: 210px;
                          margin: 0 5px;
                          border-radius: 5px;
                        "
                        v-for="(photoUrl, index) in item.arr_photo_url.slice(
                          0,
                          3
                        )"
                        :key="index"
                        class="describleImage"
                        :src="photoUrl"
                        :preview-src-list="item.arr_photo_url"
                      ></el-image>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </ChartBox>
            <ChartBox title="信息展示" v-else>
              <Swiper :dataList="dataList"></Swiper>
            </ChartBox>
          </div>
        </section>

        <article>
          <!-- 右1  -->
          <div class="box-right-one">
            <component
              v-if="module4 != ''"
              :is="moduleComponents[3]"
              style="height: 100%"
            ></component>
            <keshuInfoModule style="height: 100%" v-else></keshuInfoModule>
          </div>
          <!-- 右2  -->
          <div class="center-top">
            <component
              v-if="module5 != ''"
              :is="moduleComponents[4]"
              style="height: 100%"
            ></component>
            <monitoringInfoModule
              style="height: 100%"
              v-else
            ></monitoringInfoModule>
          </div>
          <!-- 右三 -->
          <div class="box-right-three">
            <component
              v-if="module6 != ''"
              :is="moduleComponents[5]"
              style="width: 100%; height: 100%"
            ></component>
            <aiImgsInfoModule style="height: 100%" v-else></aiImgsInfoModule>
          </div>
        </article>
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import AiHeader from "../components/aiHeader.vue";
import TabPane from "../components/tabPane.vue";
import StoreManager from "../components/storeManager.vue";
import Swiper from "../components/swiper.vue";
import { baseImgUrl } from "../utils/request";
import Rectification from "../components/rectification.vue";
import keshuInfoModule from "../components/keshuInfoModule.vue";
import healthInfoModule from "../components/healthInfoModule.vue";
import wettmpInfoModule from "..//components/wettmpInfoModule.vue";
import roomInfoModule from "../components/roomInfoModule2.vue";
import monitoringInfoModule from "../components/monitoringInfoModule.vue";
import aiImgsInfoModule from "../components/aiImgsInfoModule.vue";
import VideoModule from "../components/VideoModule.vue";
import CustomerSuggestionModule from "../components/CustomerSuggestionModule.vue";
import ProbolemShowModule from "../components/ProbolemShowModule.vue";
import StoreManagerDetailModule from "../components/StoreManagerDetailModule.vue";
import WeatherModule from "../components/WeatherModule.vue";

import { getshowImgs, getBlocks } from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";
import { getTaskRecordV2 } from "../api/api";

export default {
  data() {
    return {
      activeIndex: "1",
      taskList: [], //信息展示模块 店长助手
      newTaskList: [], //信息展示模块 店长助手
      AiHeaderQuery: false,
      dataList: [],
      tableTeight: "520",
      tabList: [],
      indexs: -1,
      module1: "",
      module2: "",
      module3: "",
      module4: "",
      module5: "",
      module6: "",
      module7: "",
      moduleComponents: [],
    };
  },
  mixins: [myMixin],
  computed: {
    searchDay() {
      const dateTimeString = new Date();
      const date = new Date(dateTimeString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份是从0开始计数的，需要加1
      const day = date.getDate();
      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      return formattedDate;
    },
    // 根据后端返回动态显示组件
    moduleComponentMap() {
      return {
        videos: VideoModule,
        customerSuggestion: CustomerSuggestionModule,
        probolemDoneRate: Rectification,
        probolemShow: ProbolemShowModule,
        storeManageRate: StoreManager,
        storeManageDetail: StoreManagerDetailModule, // 店长助手详情
        weather: WeatherModule, // 天气预报
        wettmpInfo: wettmpInfoModule,
        roomInfo: roomInfoModule,
        monitoringInfo: monitoringInfoModule,
        aiImgsInfo: aiImgsInfoModule,
        keshuInfo: keshuInfoModule,
        // storeManage: StoreManager,// 店长助手
        // 其他模块对应的组件
      };
    },
  },
  watch: {
    module1(newVal) {
      this.moduleComponents[0] = this.moduleComponentMap[newVal] || "";
    },
    module2(newVal) {
      this.moduleComponents[1] = this.moduleComponentMap[newVal] || "";
    },
    module3(newVal) {
      this.moduleComponents[2] = this.moduleComponentMap[newVal] || "";
    },
    module4(newVal) {
      this.moduleComponents[3] = this.moduleComponentMap[newVal] || "";
    },
    module5(newVal) {
      this.moduleComponents[4] = this.moduleComponentMap[newVal] || "";
    },
    module6(newVal) {
      this.moduleComponents[5] = this.moduleComponentMap[newVal] || "";
    },
    module7(newVal) {
      this.moduleComponents[6] = this.moduleComponentMap[newVal] || "";
    },
  },
  methods: {
    yingyang() {
      this.$router.replace({
        path: "/healthAi",
      });
    },
    // 信息展示模块 店长助手
    async getTaskRecordV2Date() {
      const res = await getTaskRecordV2({
        store_id: localStorage.getItem("store_id"),
        search_day: this.searchDay || this.search_day,
        from: "home",
      });
      this.taskList = res.data.data?.taskList;
      if (this.taskList) {
        this.taskList.forEach((item) => {
          if (item.time && item.time.length >= 6) {
            item.time = item.time.substring(11);
          }
          if (item.task) {
            item.task = item.task.map((t) => ({
              ...t,
              arr_score_content: t.arr_score_content.map((score) => ({
                value: score,
                checked: true,
              })),
              group_name: item.group_name,
              time: item.time,
            }));
          }
        });
        this.newTaskList = this.taskList.flatMap((item) => item.task || []);
      }
    },
    // AiHeader的刷新事件
    handleChildEvent() {
      this.tabList = [];
      this.dataList = [];
      this.isLocal = "";
      this.datas = [];
      this.getBlocksTable();
      this.getshowImgs();
      // this.getAiBox();
      // this.getImage();
    },
    // 获取信息展示
    async getshowImgs() {
      const res = await getshowImgs({ from: "home" });
      this.dataList = res.data.data.imgs.map((item) => {
        if (!item.includes("http")) {
          item = baseImgUrl + item;
        }
        return item;
      });
    },
    // 获取大屏展示内容
    async getBlocksTable() {
      const res = await getBlocks({ from: "home" });
      this.tabList = res.data.data.map((str) => {
        return { label: str };
      });
    },
  },
  components: {
    ChartBox,
    Chart,
    AiHeader,
    TabPane,
    Swiper,
    StoreManager,
    Rectification,
    keshuInfoModule,
    healthInfoModule,
    wettmpInfoModule,
    roomInfoModule,
    monitoringInfoModule,
    aiImgsInfoModule,
    VideoModule,
    CustomerSuggestionModule,
    ProbolemShowModule,
    StoreManagerDetailModule,
    WeatherModule,
  },
  created() {
    this.getTaskRecordV2Date();
    this.getBlocksTable();
    this.getshowImgs();
  },
  mounted() {
    document.title = "优食安数字食堂云平台";
    if (this.$route.query.from === "aiHeader") {
      this.AiHeaderQuery = true;
    }
    if (
      localStorage.getItem("show_block") != "" &&
      localStorage.getItem("show_block") != null
    ) {
      var responseData = JSON.parse(localStorage.getItem("show_block"));
      Object.keys(responseData).forEach((key) => {
        if (key === "1") {
          this.module1 = responseData[key]; // 将键值为2的value放入module2中
        } else if (key === "2") {
          this.module2 = responseData[key]; // 将键值为2的value放入module2中
        } else if (key === "3") {
          this.module3 = responseData[key]; // 将键值为2的value放入module2中
        } else if (key === "5") {
          this.module5 = responseData[key]; // 将键值为5的value放入module5中
        } else if (key === "6") {
          this.module6 = responseData[key]; // 将键值为6的value放入module6中
        } else if (key === "4") {
          this.module4 = responseData[key]; // 将键值为6的value放入module7中
        } else if (key === "7") {
          this.module7 = responseData[key];
        }
        //以根据需要添加更多的条件判断和对应的module赋值
      });
    }
  },

  beforeDestroy() {
    clearInterval(this.cleanUpMemory);
    this.index = 0;
  },
};
</script>
<style lang="scss" scoped>
.elItem {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .el {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .guize {
      overflow: hidden;
      background-color: white;
      width: 35%;
      height: 90%;
      border-radius: 5px;
      margin: 0 10px;

      color: black;
      padding: 5px 10px 0 10px;
      .oneTitle {
        width: 100%;
        font-size: 22px;
        color: rgb(230, 126, 34);
        font-weight: bolder;
        margin-bottom: 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
      }
      .twoTitle {
        .tow {
          display: flex;
          align-items: center;
          margin: 10px 0;
        }
        .describle {
          width: 100%;
          font-weight: bolder;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
        }
      }
    }
  }
}
.carouselBox {
  margin: 0 auto;
  width: 100%;
  height: 230px;
  .carousel {
    width: 100%;
    height: 420px;
    .el-car-item {
      width: 100%;
      height: 420px;
      display: flex;
      .divSrc {
        width: 323px;
        height: 230px;
        background: #fff;
        margin-right: 46px;

        .img {
          width: 323px;
          height: 230px;
        }
        .title {
          width: 90%;
          height: 80px;
          line-height: 80px;
          margin: 0 auto;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #222222;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    ::v-deep .el-carousel__arrow {
      background: #99a9bf !important;
      display: block !important;
      margin-top: 65px;
    }
  }
  .el-car-item {
    width: 100%;
    display: flex;

    .img {
      width: 284px;
      height: 184px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

.container {
  height: 100%;
  width: 100%;
  background-color: #ecf9f8;
  .header {
    padding: 0px 20px;
    background-color: #00b58b;
    justify-content: space-between;
    align-items: center;
    display: flex;
    .shopName {
      font-size: 28px;
      color: white;
      line-height: 70px;
      padding-left: 7px;
      font-family: "微软雅黑";
      font-weight: 600;
    }
    .other {
      align-items: center;
      display: flex;
      width: 20%;
      justify-content: space-around;
      .msg,
      .head {
        width: 43px;
        height: 43px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
    }
  }
}

/* 中间 */
.box-container {
  min-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
  height: calc(100% - 130px);
  > section,
  > aside,
  > article {
    flex: 0 0 20%;
    width: 0;
    > * {
      height: 290px;
      & + * {
        margin-top: 20px;
      }
    }

    .center-buttom {
      box-sizing: border-box;
      height: 598px;
      border: 2px solid #00b58b;
      border-radius: 10px;
      padding: 0 10px;
    }
  }
  > section {
    flex: 1;
    margin: 0 20px;
  }
}
</style>
<style lang="scss" scoped>
// 表单格式
::v-deep .el-table {
  .el-table__body {
    height: 100%;
  }
}
// 表格单元格
::v-deep .box-container .el-table .el-table__cell {
  padding: 20px 7px;
  color: #333 !important;
  background-color: #ecf9f8 !important;
}
// 表头
::v-deep .el-table th.el-table__cell.is-leaf {
  padding: 20px 0 !important;
  font-size: 16px;
  border: none !important;
}
::v-deep .box-container .el-tabs .el-tabs__item:not(:first-child) {
  color: #00b58b;
}

::v-deep .box-container .el-tabs .el-tabs__item:hover {
  color: #00b58b !important;
}
::v-deep .el-dropdown .el-button-group {
  display: flex;
}
::v-deep .el-button-group > .el-button:not(:last-child) {
  width: 100px;
}
::v-deep .el-button {
  text-overflow: ellipsis;
  overflow: hidden;
}

.el-tabs__active-bar {
  background-color: #00b58b !important;
}

.box-container .el-tabs {
  width: 100%;
  color: #fffff0;
}

.center-buttom .el-tab-pane .el-image {
  width: 40px;
  height: 40px;
}

// Card样式
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 50%;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.center-buttom .el-button {
  padding: 3px 4px;
}
::v-deep .el-menu--horizontal > .el-menu-item {
  font-size: 18px;
  font-weight: 500;
}
::v-deep .el-menu {
  background-color: #ecf9f8;
}
</style>
