<template>
  <div>
    <ChartBox title="食堂信息">
      <div class="logo" v-if="shop_name != '合肥十一中学食堂（优芙得）'">
        <img :src="shop_img" />
        <div style="color: white">
          <div class="shop_name" style="">
            <span>{{ shop_name }}</span>
          </div>
          <div class="shop_owner">
            <span>负 责 人 ：</span>
            <span>{{ shop_owner }}</span>
          </div>
          <div class="phone">
            <span>联系电话：</span>
            <span>{{ shop_phone }}</span>
          </div>
          <div class="phone">
            <span>餐厅面积：</span>
            <span>{{ area }}</span>
          </div>
          <div class="phone">
            <span>就餐人数：</span>
            <span>{{ users_amount }}</span>
          </div>
          <div class="shop_address">
            <span>餐厅地址：</span>
            <span>{{ shop_address }}</span>
          </div>
        </div>
      </div>
      <div
        v-else
        style="
          color: white;
          margin-left: 30px;
          margin-top: 15px;
          font-weight: bolder;
          font-size: 14px;
          position: relative;
        "
      >
        <div style="margin: 5px 0">
          <span>责任人姓名：</span>
          <span>方建军</span>
        </div>
        <div style="margin-bottom: 5px">
          <span>职务：</span>
          <span>安全处主任</span>
        </div>
        <div style="margin-bottom: 5px">
          <span>手机号码：</span>
          <span>13856047387</span>
        </div>
        <div>
          <span>电子邮箱：</span>
          <span>634153012@qq.com</span>
        </div>
        <div
          style="
            width: 90%;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.5);
            position: absolute;
            top: 98px;
          "
        ></div>
        <div style="margin-top: 18px; margin-bottom: 5px">
          <span>食堂责任人姓名：</span>
          <span>仇保萍</span>
        </div>
        <div style="margin-bottom: 5px">
          <span>职务：</span>
          <span>食堂经理</span>
        </div>
        <div style="margin-bottom: 5px">
          <span>手机号码：</span>
          <span>15155127728</span>
        </div>
        <div>
          <span>电子邮箱：</span>
          <span>3031939648@qq.com</span>
        </div>
      </div>
    </ChartBox>
  </div>
</template>

<script>
import ChartBox from "../components/chartBox.vue";
import { baseImgUrl } from "../utils/request";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {};
  },
  mixins: [myMixin],
  components: {
    ChartBox,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: 50px;
  }
  .shop_name {
    margin: 10px 0;
    width: 240px;
    font-weight: bolder;
  }
  .shop_owner {
    margin: 10px 0;
  }
  .phone {
    margin-bottom: 10px;
  }
  .shop_address {
    line-height: 1.5;
    width: 240px;
  }
}
</style>
