<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <Time style="position: absolute; top: 40px; left: 150px"></Time>
      <weather
        style="position: absolute; top: 50px; right: 150px; z-index: 99"
      ></weather>
      <!-- 标题栏部分 -->
      <AiHeader ref="mychild" :analysis="true"></AiHeader>
      <div class="box-container">
        <aside>
          <!-- 门店信息 -->
          <div data-title="门店信息" class="box-left-one">
            <div class="logo">
              <img :src="shop_img" />
              <div class="bg">
                <div class="phone" style="margin: 5px 0; font-size: 22px">
                  <span>{{ shop_name }}</span>
                </div>
                <div class="phone">
                  <span>负 责 人 ：</span>
                  <span>{{ shop_owner }}</span>
                </div>
                <div class="phone">
                  <span>联系电话：</span>
                  <span>{{ shop_phone }}</span>
                </div>
                <div class="phone">
                  <span>餐厅面积：</span>
                  <span>{{ area }}</span>
                </div>
                <div class="phone">
                  <span>就餐人数：</span>
                  <span>{{ users_amount }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="box-left-two" data-title="菜品信息" style="height: 550px">
            <el-carousel
              ref="leftCarousel"
              indicator-position="none"
              :interval="20000"
              @change="handleCarouselChange"
            >
              <el-carousel-item
                class="carousel-item"
                v-for="(item, index) in list"
                :key="index"
              >
                <div class="titleBox">
                  <div class="title">{{ item.name }}</div>
                  <div class="canci" v-if="item.week || item.meal_name">
                    <span>{{ item.week }}</span>
                    <span style="margin-left: 5px">{{ item.meal_name }}</span>
                  </div>
                </div>
                <div class="contentBox">
                  <el-image
                    class="img"
                    style="
                      width: 330px;
                      height: 220px;
                      border-radius: 10px;
                      margin-right: 20px;
                    "
                    :src="item.food_images || defaultImg"
                    :preview-src-list="[item.food_images || defaultImg]"
                  ></el-image>
                </div>
                <div
                  style="
                    margin-top: 20px;
                    color: #999;
                    font-size: 15px;
                    margin-left: 5px;
                  "
                >
                  <div v-if="item.weight">
                    <img
                      src="../assets/image/zhongliang.png"
                      alt=""
                      style="
                        width: 25px;
                        vertical-align: middle;
                        margin-right: 10px;
                      "
                    /><span>重量：{{ item.weight }}克/份</span>
                  </div>
                  <div style="margin: 15px 0" v-if="item.price">
                    <img
                      src="../assets/image/price1.png"
                      alt=""
                      style="
                        width: 25px;
                        vertical-align: middle;
                        margin-right: 10px;
                      "
                    /><span>价格：￥{{ item.price }}元</span>
                  </div>
                  <div
                    style="margin: 15px 0"
                    v-if="item.red != '' || item.yellow != ''"
                  >
                    <img
                      src="../assets/image/jinzhi2.png"
                      alt=""
                      style="
                        width: 23px;
                        vertical-align: middle;
                        margin-right: 10px;
                      "
                    />
                    <span>
                      提示：
                      <span class="desc" v-for="(k, index) in item.red"
                        >{{ k
                        }}{{ index < item.red.length - 1 ? "、" : "" }}</span
                      ></span
                    ><span class="desc" v-for="(k, index) in item.red"
                      >{{ k
                      }}{{ index < item.yellow.length - 1 ? "、" : "" }}</span
                    >
                  </div>
                </div>
                <div class="jinzhi" v-if="item.specialCareful">
                  <img src="../assets/image/jinzhi.png" /><span
                    ><span>慎用人群：</span>{{ item.specialCareful }}</span
                  >
                </div>
                <div class="guomin" v-if="item.allergy">
                  <img src="../assets/image/guomin.png" />
                  <span>过敏源：</span>
                  <span>{{ item.allergy }}</span>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </aside>

        <section>
          <!--每周菜品营养分析 -->
          <div
            data-title="营养分析数据明细"
            class="center-top"
            style="height: 92%; padding: 10px"
          >
            <el-carousel
              ref="rightCarousel"
              indicator-position="none"
              :interval="20000"
              style="height: 900px !important; margin-top: 10px"
            >
              <el-carousel-item
                class="carousel-item"
                v-for="(item, index) in analysisList"
                :key="index"
                style="height: 900px !important;text-align: center;"
              >
                <img
                  v-if="item"
                  :src="item"
                  style="width: 100%; height: 100%; opacity: 0.95"
                />
                <img v-else :src="defaultImg2" style="width: 500px; height: 300px; margin-top: 300px;">
              </el-carousel-item>
            </el-carousel>
          </div>
        </section>
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import AiHeader from "../components/aiHeader.vue";
import Swiper from "../components/swiper.vue";
import foodAnalysis from "../components/foodAnalysis.vue";
import Time from "../components/time.vue";
import weather from "..//components/weather.vue";
// 引入混入
import { myMixin } from "../common/common.js";
import { getFoodAnalysis } from "../api/api";
export default {
  data() {
    return {
      defaultImg:
        "https://shuwangysa.oss-cn-hangzhou.aliyuncs.com/imgs/nofood.png",
      defaultImg2: require("../assets/image/NoImg.png"),
      list: [],
      analysisList: [],
      activeIndex: 0,
    };
  },
  mixins: [myMixin],
  methods: {
    handleCarouselChange(currentIndex) {
      this.$refs.rightCarousel.setActiveItem(currentIndex);
    },
    async getgetFoodAnalysisData() {
      const res = await getFoodAnalysis();
      this.list = res.data.data?.map((item) => {
        if (!item.food_images?.includes("http") && item.food_images != "") {
          return {
            ...item,
            food_images:
              "https://shuwangysa.oss-cn-hangzhou.aliyuncs.com" +
              item.food_images,
            food_info: item.food_info?.map((foodItem) => ({
              ...foodItem,
              value: foodItem.percent,
            })),
          };
        } else {
          return {
            ...item,
            food_info: item.food_info?.map((foodItem) => ({
              ...foodItem,
              value: foodItem.percent,
            })),
          };
        }
      });
      this.analysisList = this.list?.map((item) => {
        return item.food_analysis_img;
      });
    },
  },
  components: {
    AiHeader,
    foodAnalysis,
    Swiper,
    weather,
    Time,
  },
  created() {
    this.getgetFoodAnalysisData();
  },
  mounted() {
    document.title = "优食安数字食堂云平台";
  },
};
</script>
<style lang="scss" scoped>
// * 覆盖轮播箭头样式 */
.box-left-two::v-deep .el-carousel__arrow {
  width: 35px;
  height: 35px;
  margin-top: -100px;
  background: rgba(24, 144, 255, 0.35) !important;
  backdrop-filter: blur(2px);
  border: 2px solid #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  // transition: all 0.3s ease;
}

/* 悬停效果 */
.box-left-two::v-deep .el-carousel__arrow:hover {
  background: rgba(24, 144, 255, 0.9) !important;
  // transform: scale(1.1);
  box-shadow: 0 6px 16px rgba(24, 144, 255, 0.4);
}

/* 箭头图标颜色 */
.box-left-two::v-deep .el-carousel__arrow i {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

/* 左右按钮位置微调 */
.box-left-two::v-deep .el-carousel__arrow--left {
  left: 20px;
}

.box-left-two::v-deep .el-carousel__arrow--right {
  right: 20px;
}
::v-deep .el-carousel__container {
  height: 550px;
}
.container {
  height: 100%;
  width: 100%;
  background: url("../assets/image/bg.577ccc4c.jpg");
  .box-left-one,
  .box-left-two,
  .box-left-three,
  .box-left-five,
  .center-bottom {
    position: relative;
    border-radius: 20px;
    padding: 60px 20px 10px 20px;
    border: 1px solid #25a6f3;
    box-shadow: 0px 0px 15px 0px #2adcff inset;
  }

  .box-left-four {
    position: relative;
    border-radius: 5px;
    padding: 20px 30px 10px 30px;
    border: 5px solid #25a6f3;
    box-shadow: 0px 0px 10px 0px #2adcff inset;
  }
  .center-top {
    position: relative;
    border-radius: 5px;
    // padding: 20px 30px 10px 30px;
    box-shadow: 0px 0px 10px 0px #2adcff inset;
  }
  .box-left-one:before,
  .box-left-two:before,
  .center-top::before,
  .box-left-three:before,
  .center-bottom:before,
  .box-left-four:before,
  .box-left-five:before {
    content: attr(data-title);
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #25a6f3;
    color: #fff;
    padding: 0px 30px;
    border-radius: 5px;
    border-bottom: 10px solid #25a6f3;
    padding-top: 5px;
    font-size: 22px;
    font-weight: bolder;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
    z-index: 999;
  }

  .box-left-one {
    .logo {
      display: flex;
      align-items: center;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 20px;
        opacity: 0.8;
      }
      .bg {
        color: white;
        padding-left: 20px;
        margin-top: 5px;
        z-index: 999;
        font-weight: bolder;
        background: rgba(52, 152, 219, 0.3) !important;
        width: 100%;
        height: 100%;
        font-size: 18px;
        .phone {
          margin: 15px 0;
        }
      }
    }
  }
  .box-left-two {
    .titleBox {
      position: relative;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      font-weight: bolder;
      font-size: 19px;
      .title {
        color: white;
        font-size: 30px;
        margin-bottom: 10px;
        // margin-right: 5px;
      }
      .price {
        color: #b3b2c2;
        span {
          color: #bfbb71;
          font-size: 18px;
        }
      }
      .canci {
        height: 40px;
        padding: 0px 10px;
        background-color: rgba(255, 255, 159, 0.8);
        position: absolute;
        right: 0px;
        top: 0px;
        border-radius: 9px;
        line-height: 40px;
        text-align: center;
        color: #333;
      }
    }
    .jinzhi,
    .guomin,
    .tujian {
      color: #999;
      font-size: 15px;
      margin: 15px 0;
      margin-left: 5px;
      display: flex;
      align-items: center;
      img {
        width: 20px;
        margin-right: 10px;
      }
    }
  }
}

/* 中间 */
.box-container {
  min-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 30px 20px;
  height: calc(100% - 50px);
  > section,
  > aside,
  > article {
    flex: 0 0 20%;
    width: 0;
    > * {
      height: 230px;
      & + * {
        margin-top: 20px;
      }
    }

    .center-top {
      box-sizing: border-box;
      height: 460px;
    }
    .box-left-five {
      height: calc(100% - 440px);
    }
  }
  > section {
    flex: 1;
    margin: 0 20px;
  }
}
</style>
