<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <Time style="position: absolute; top: 40px; left: 150px"></Time>
      <weather
        style="position: absolute; top: 50px; right: 150px; z-index: 99"
      ></weather>
      <!-- 标题栏部分 -->
      <AiHeader
        ref="mychild"
        :dianzhang="true"
        :AiHeaderQuery="AiHeaderQuery"
      ></AiHeader>
      <div class="storageContainer">
        <div class="left">
          <div>
            <!-- 门店信息 -->
            <div
              data-title="门店信息"
              class="card columns"
              style="margin-bottom: 30px"
            >
              <div class="logo" style="display: flex; align-items: center">
                <img
                  :src="shop_img"
                  style="
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 20px;
                    opacity: 0.8;
                  "
                />
                <div
                  style="
                    color: white;
                    padding-left: 20px;
                    margin-top: 5px;
                    z-index: 999;
                    font-weight: bolder;
                    background: rgba(52, 152, 219, 0.3) !important;
                    width: 100%;
                    height: 80%;
                  "
                >
                  <div class="phone" style="margin: 5px 0; font-size: 24px">
                    <span>{{ shop_name }}</span>
                  </div>
                  <div class="phone" style="margin: 5px 0">
                    <span>负 责 人 ：</span>
                    <span>{{ shop_owner }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>联系电话：</span>
                    <span>{{ shop_phone }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>餐厅面积：</span>
                    <span>{{ area }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>就餐人数：</span>
                    <span>{{ users_amount }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- 任务统计 -->
            <div class="card columns total" data-title="课程统计">
              <div style="display: flex;align-items: center;width: 48%;background-color: rgba(17,126,75,0.2);border-radius: 20px;margin:0 4px 8px;height: 180px;">
                <div style="padding:0 30px;text-align: center;border-right: 2px solid #EEEEEE;">
                  <img src="../assets/image/shian.png" alt="">
                  <div style="margin-top: 10px;font-weight: bolder;color: #666;width: 50px;">食安管理</div>
                </div>
                <div style="width: 50%;text-align: center;font-size: 28px;font-weight: 400;color: #00B589;">30</div>
              </div>
              <div style="display: flex;align-items: center;width: 48%;background-color: rgba(17,126,75,0.2);border-radius: 20px;margin:0 4px 8px;height:180px;">
                <div style="padding:0 30px;text-align: center;border-right: 2px solid #EEEEEE;">
                  <img src="../assets/image/xuanchuan.png" alt="">
                  <div style="margin-top: 10px;font-weight: bolder;color: #666;width: 50px;">企业宣传</div>
                </div>
                <div style="width: 50%;text-align: center;font-size: 28px;font-weight: 400;color: #00B589;">3</div>
              </div>
              <div style="display: flex;align-items: center;width: 48%;background-color: rgba(17,126,75,0.2);border-radius: 20px;margin:0 4px 8px;height:180px;">
                <div style="padding:0 30px;text-align: center;border-right: 2px solid #EEEEEE;">
                  <img src="../assets/image/qingmiao.png" alt="">
                  <div style="margin-top: 10px;font-weight: bolder;color: #666;width: 50px;">青苗计划</div>
                </div>
                <div style="width: 50%;text-align: center;font-size: 28px;font-weight: 400;color: #00B589;">20</div>
              </div>
              <div style="display: flex;align-items: center;width: 48%;background-color: rgba(17,126,75,0.2);border-radius: 20px;margin:0 4px 8px;height:180px;">
                <div style="padding:0 30px;text-align: center;border-right: 2px solid #EEEEEE;">
                  <img src="../assets/image/cook.png" alt="">
                  <div style="margin-top: 10px;font-weight: bolder;color: #666;width: 50px;">厨政课堂</div>
                </div>
                <div style="width: 50%;text-align: center;font-size: 28px;font-weight: 400;color: #00B589;">30</div>
              </div>
              <div style="display: flex;align-items: center;width: 48%;background-color: rgba(17,126,75,0.2);border-radius: 20px;margin:0 4px 8px;height:180px;">
                <div style="padding:0 30px;text-align: center;border-right: 2px solid #EEEEEE;">
                  <img src="../assets/image/tisheng.png" alt="">
                  <div style="margin-top: 10px;font-weight: bolder;color: #666;width: 50px;">能力提升</div>
                </div>
                <div style="width: 50%;text-align: center;font-size: 28px;font-weight: 400;color: #00B589;">30</div>
              </div>
              <div style="display: flex;align-items: center;width: 48%;background-color: rgba(17,126,75,0.2);border-radius: 20px;height:180px;">
                <div style="padding:0 30px;text-align: center;border-right: 2px solid #EEEEEE;">
                  <img src="../assets/image/fengcai.png" alt="">
                  <div style="margin-top: 10px;font-weight: bolder;color: #666;width: 50px;">团队风采</div>
                </div>
                <div style="width: 50%;text-align: center;font-size: 28px;font-weight: 400;color: #00B589;">30</div>
              </div>
            </div>
          </div>
        </div>
        <div data-title="学习中心" class="right columns">
          <div class="search">
            <div class="yijiS">
              <div class="all">全部分类</div>
              <div
                class="yiL"
                v-for="(item, index) in yijiList"
                :key="index"
                :class="{ active: currentIndex === index }"
                @click="changeActive(index)"
              >
                {{ item }}
              </div>
            </div>
            <!-- <div class="erjiS">
              <div class="type">类别</div>
              <div
                class="yiL"
                v-for="(item, index) in erjiList"
                :key="index"
                :class="{ active: currentIndex2 === index }"
                @click="changeActive2(index)"
              >
                {{ item }}
              </div>
            </div> -->
          </div>
          <div class="body">
            <div class="bodyTop">
              <div style="display: flex; align-items: center">
                <!-- <div
                  class="tab"
                  v-for="(item, index) in bodyTop"
                  :key="index"
                  :class="{ tabActive: currentIndex3 === index }"
                  @click="changeActive3(index)"
                >
                  {{ item }}
                </div> -->
                <div class="input">
                  <el-input v-model="input" placeholder="请输入内容"></el-input>
                  <el-button
                    icon="el-icon-search"
                    round
                    type="primary"
                    style="margin-left: 20px"
                  ></el-button>
                </div>
              </div>

              <div class="total">
                共<span style="color: #333">256</span>个任务
              </div>
            </div>
            <div class="bodyBottom">
              <div class="videoBox">
                <div class="videoItem" v-for="(item, index) in 20" :key="index" @click="openDialog">
                  <div class="itemLeft">
                    <img
                      src="https://img-bss.csdnimg.cn/20241221172438869.jpg"
                      class="cover"
                    />
                    <span class="tip">会员免费</span>
                    <div class="mask">
                      <img src="../assets/image/play.png" class="video_img" />
                    </div>
                  </div>
                  <div class="itemRight">
                    <div class="itemTitle">
                      雪梨锅包肉
                    </div>
                    <div class="itemContent">
                      华为认证讲师，微软MVP，ORACLE认证专家，著有《Java编程讲义》，主要研究方向为J2EE、人工智能、大数据、数据库
                      、前端，曾经服务过中烟、中铝等上市国企IT部门，软件企业联合创始人，对软件研发管理、市场营销有自己独特思想体系！
                    </div>
                    <div class="itemFooter">
                      <span style="margin-right: 20px">共12节</span
                      ><span>259人学习</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog title="课堂列表" :visible.sync="dialogVisible" width="60%" :modal="false">
        <div class="dialogBox"></div>
      </el-dialog>
    </div>
  </v-scale-screen>
</template>
<script>
import AiHeader from "../components/aiHeader.vue";
import Chart from "../components/chart.vue";
import Echarts from "../components/echarts";
import Time from "../components/time.vue";
import weather from "..//components/weather.vue";
import { baseImgUrl } from "../utils/request";
import { getTaskDoRate } from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      secondContent: [],
      secondContentSrc: [],
      secondContentSrc2: [],
      yijiList: ["全部", "食安管理", "企业宣传", "青苗计划", "厨政课堂","能力提升"," 团队风采"],
      erjiList: [
        "任职资格任职书",
        "青苗计划Young Sprouts Project",
        "厨政小课堂",
        "职业素养",
        "授客学堂",
        "外部课程",
        "储干",
        "管/厨培生计划",
      ],
      bodyTop: ["默认", "最热", "最新"],
      currentIndex: 0,
      currentIndex2: 0,
      currentIndex3: 0,
      completedPercent: null, //完成率
      completedNum: null, //完成数量
      todpercent: null, //待完成完成率
      todNum: null, //待完成数量
      overtimePercent: null, //超时完成率
      overtimeNum: null, //超时数量
      soon_end_percentage: null, //即将超时完成率
      soon_end_num: null, //即将超时数量
      total_tasks: null, //总任务数
      timer: null,
      AiHeaderQuery: false,
      dialogVisible: false,
    };
  },
  mixins: [myMixin],
  computed: {
    searchDay() {
      const dateTimeString = new Date();
      const date = new Date(dateTimeString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份是从0开始计数的，需要加1
      const day = date.getDate();
      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      return formattedDate;
    },
  },
  methods: {
    // 获取店长助手完成率
    async getTaskDoRateData() {
      const res = await getTaskDoRate({
        store_id: localStorage.getItem("store_id"),
      });
      this.completedPercent = res.data.data.day.completion_percentage;
      this.completedNum = res.data.data.day.completed_tasks;
      this.todpercent = res.data.data.day.todo_percentage;
      this.todNum = res.data.data.day.todo_tasks;
      this.overtimePercent = res.data.data.day.overtime_percentage;
      this.overtimeNum = res.data.data.day.overtime_tasks;
      this.soon_end_percentage = res.data.data.day.soon_end_percentage;
      this.soon_end_num = res.data.data.day.soon_end;
      this.total_tasks = res.data.data.day.total_tasks;
    },
    changeActive(index) {
      this.currentIndex = index;
    },
    changeActive2(index) {
      this.currentIndex2 = index;
    },
    changeActive3(index) {
      this.currentIndex3 = index;
    },
    // 打开弹窗
    openDialog(id) {
      this.dialogVisible = true;
    },
    // 关闭dialog
    closeDialog() {},
  },
  components: {
    AiHeader,
    Echarts,
    Chart,
    Time,
    weather,
  },
  created() {
    this.getTaskDoRateData();
  },
  mounted() {
    document.title = "优食安数字食堂云平台";
    if (this.$route.query.from === "aiHeader") {
      this.AiHeaderQuery = true;
    }
    this.timer = setInterval(() => {
      this.secondContent = [];
      this.secondContentSrc = [];
      this.completedPercent = null; //完成率
      this.completedNum = null; //完成数量
      this.todpercent = null; //待完成完成率
      this.todNum = null; //待完成数量
      this.overtimePercent = null; //超时完成率
      this.overtimeNum = null; //超时数量
      this.soon_end_percentage = null; //即将超时完成率
      this.soon_end_num = null; //即将超时数量
      this.total_tasks = null; //总任务数
      this.getTaskDoRateData();
    }, 180000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  background: linear-gradient(170deg, #000a65 0%, #020924 100%);
  // background-color: #91bcf1;
}

/* 中间 */
.storageContainer {
  min-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 40px 20px 20px 20px;
  height: calc(100% - 160px);
  .left {
    width: 420px;
    height: 100%;
    display: flex;
    .columns {
      font-size: 18px;
      height: 21%;
      width: 410px;
      position: relative;
      border-radius: 20px;
      padding: 25px 5px 5px 10px;
      border: 2px solid white;
      transition: all 0.4s;
    }
    .total {
      display: flex;
      flex-wrap: wrap;
      padding: 35px 2px 0px;
      height: 69%;
      position: relative; 
      background-color: white;
    }

    .columns:hover {
      box-shadow: 4px 4px 0 1px rgba(255, 255, 255);
    }
  }
  .right {
    position: relative;
    width: calc(100% - 460px);
    height: 100%;
    justify-content: space-between;
    border: 2px solid white;
    transition: all 0.4s;
    border-radius: 20px;
    background-color: #f5f5f5;
    .search {
      width: calc(100% - 32px);
      // height: 150px;
      padding: 35px 16px 20px;
      background-color: white;
      border-radius: 20px;
      .yijiS,
      .erjiS {
        display: flex;
        margin: 10px 0;
        .all,
        .type {
          // display: inline-block;
          text-align: center;
          width: 82px;
          margin-top: 1px;
          height: 38px;
          line-height: 38px;
          background: #dadfe1;
          color: #333;
          font-weight: 600;
          border-radius: 17px 0 17px 17px;
          margin-right: 25px;
          font-size: 18px;
        }
        .yiL {
          cursor: pointer;
          height: 38px;
          line-height: 38px;
          padding: 0 8px;
          color: #555666;
          border: 0;
          outline: 0;
          -webkit-box-shadow: none;
          box-shadow: none;
          border-radius: 17px;
          margin-right: 15px;
          margin-bottom: 8px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          font-size: 18px;
        }
        .active {
          color: #fc5531;
          background: #feeeea;
          font-weight: bolder;
        }
      }
    }
    .body {
      width: calc(100% - 50px);
      height: calc(100% - 200px);
      padding: 15px 26px 0px;
      background-color: white;
      margin-top: 40px;
      border-radius: 20px;
      .bodyTop {
        width: 100%;
        margin-bottom: 12px;
        display: flex;
        // align-items: center;
        justify-content: space-between;
        .tab {
          display: inline-block;
          cursor: pointer;
          color: #555666;
          position: relative;
          line-height: 38px;
          margin-right: 36px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          font-size: 18px;
        }
        .tabActive {
          color: #555666;
          font-weight: 600;
        }
        .tabActive:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 70%;
          height: 2px;
          background-color: #1c1d30;
        }
        .total {
          color: #666;
          align-self: end;
        }
      }
      .bodyBottom {
        width: 100%;
        height: calc(100% - 80px);
        .videoBox {
          padding: 10px 5px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          height: 100%;
          overflow-y: scroll;
          .videoItem {
            display: flex;
            width: calc(50% - 20px);
            height: 160px;
            padding: 12px 8px;
            border-radius: 4px;
            .itemLeft {
              width: 240px;
              -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
              border-radius: 4px;
              position: relative;
              margin-right: 16px;
              background-repeat: no-repeat;
              background-position: 50%;
              background-size: cover;
              -webkit-transform: scale(1);
              transform: scale(1);
              -webkit-transition: all 0.3s;
              transition: all 0.3s;
              display: block;
              -ms-flex-negative: 0;
              flex-shrink: 0;
              .cover {
                width: 240px;
                height: 100%;
                border-radius: 4px;
              }
              .tip {
                position: absolute;
                left: 8px;
                top: 8px;
                height: 24px;
                border-radius: 2px;
                font-size: 14px;
                text-align: center;
                font-weight: 400;
                line-height: 24px;
                padding: 2px 6px;
                background: linear-gradient(270deg, #f8c883, #fae8d0);
                color: #69421b;
              }
              .mask {
                /* styles for .mask when hovered over */
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                opacity: 0;
                border-radius: 4px 4px 0 0;
                width: 240px;
                height: 100%;
                border-radius: 4px;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.4);
                z-index: 10;
              }
              .mask:hover {
                opacity: 1;
              }

              .video_img {
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 40px;
                height: 40px;
                opacity: 0;
              }
              .mask:hover .video_img {
                opacity: 1;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                -webkit-transform: scale(3);
                transform: scale(1.8);
                top: 60px;
                left: 100px;
              }
            }
            .itemRight {
              -webkit-box-flex: 1;
              -ms-flex: 1;
              flex: 1;
              position: relative;
              overflow: hidden;
              .itemTitle {
                font-size: 18px;
                display: inline-block;
                color: #222226;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 100%;
                min-width: 0;
                vertical-align: middle;
                margin-bottom: 10px;
              }
              .itemContent {
                width: 100%;
                height: 48px;
                font-size: 16px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 400;
                color: #999aaa;
                line-height: 24px;
                text-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
                margin-bottom: 26px;
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                margin-top: 8px;
              }
              .itemFooter {
                width: 100%;
                height: 28px;
                margin-top: 45px;
              }
            }
          }
          .videoItem:hover {
            background-color: #efeff0;
            box-shadow: 0 0 5px rgba(128, 128, 128, 0.4);
            background-color: #efeff0;
          }
        }
      }
    }
  }
  /* 添加标题样式 */
  .columns:before {
    content: attr(data-title);
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #25a6f3;
    color: #fff;
    font-size: 20px;
    font-weight: bolder;
    padding: 0px 30px;
    border-radius: 5px;
    border-bottom: 10px solid #25a6f3;
    padding-top: 5px;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
    z-index: 99;
  }

  .bottom:before,
  .top:before {
    padding-top: 10px;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table .warning-row {
  background: oldlace;
}
::v-deep .el-table__empty-block {
  background-color: rgb(2, 19, 49);
}
::v-deep .el-table__body-wrapper {
  background-color: rgb(2, 19, 49);
}

::v-deep .el-table .success-row {
  background: #f0f9eb;
}
::v-deep .el-carousel--horizontal {
  width: 820px !important;
}
// 已完成表格内背景颜色
.yiwanchengTable ::v-deep .el-table th,
.yiwanchengTable ::v-deep .el-table tr,
.yiwanchengTable ::v-deep .el-table td {
  background-color: rgb(2, 19, 49); // 背景透明
  border-bottom: 1px dashed rgba(42, 165, 21, 0.4);
  color: rgb(42, 165, 21);
  font-size: 22px;
  text-align: center;
  height: 5px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
}
// 即将超时表格内背景颜色
.jijiangTable ::v-deep .el-table th,
.jijiangTable ::v-deep .el-table tr,
.jijiangTable ::v-deep .el-table td {
  background-color: rgb(2, 19, 49); // 背景透明
  border-bottom: 1px dashed rgba(243, 156, 18, 0.4);
  color: rgb(240, 255, 0);
  font-size: 22px;
  text-align: center;
  height: 5px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
}
// 已超时表格内背景颜色
.yichaoshiTable ::v-deep .el-table th,
.yichaoshiTable ::v-deep .el-table tr,
.yichaoshiTable ::v-deep .el-table td {
  background-color: rgb(2, 19, 49); // 背景透明
  border-bottom: 1px dashed #e74d3c59;
  color: rgb(242, 38, 19);
  font-size: 22px;
  text-align: center;
  height: 5px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
}
// 待完成表格内背景颜色
.daiwancheng ::v-deep .el-table th,
.daiwancheng ::v-deep .el-table tr,
.daiwancheng ::v-deep .el-table td {
  background-color: rgb(2, 19, 49); // 背景透明
  border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  color: white;
  font-size: 22px;
  text-align: center;
  height: 5px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
}
// 去掉最下面的那一条线
::v-deep .el-table::before {
  height: 0px;
}
// 修改表头样式-加边框
::v-deep .el-table__header-wrapper {
  border: solid 2px #063570;
  box-sizing: border-box;
}

// 修改高亮当前行颜色
::v-deep .el-table tbody tr:hover > td {
  background: #063570 !important;
}
// 滚动条样式
::v-deep
  .storageContainer
  .right
  .body
  .bodyBottom
  .videoBox::-webkit-scrollbar {
  width: 6px !important;
}

::v-deep
  .storageContainer
  .right
  .body
  .bodyBottom
  .videoBox::-webkit-scrollbar-track-piece {
  background: #fcfcfc !important;
}

::v-deep
  .storageContainer
  .right
  .body
  .bodyBottom
  .videoBox::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 20px;
}
::v-deep .el-table {
  th.gutter,
  colgroup.gutter {
    width: 4px !important; //此处的宽度值，对应你自定义滚动条的宽度即可
  }
}
::v-deep .el-table {
  th.gutter,
  colgroup.gutter {
    width: 4px !important; //此处的宽度值，对应你自定义滚动条的宽度即可
  }
}
// 关键css代码
::v-deep .el-table__header colgroup col[name="gutter"] {
  display: table-cell !important;
}
::v-deep .el-table__body {
  width: 101% !important;
}
::v-deep .el-input {
  width: 300px;
}
::v-deep .el-input__inner {
  border-radius: 21px;
  padding: 0 24px 0 16px;
}
</style>
